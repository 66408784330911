import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { IoIosArrowBack } from "react-icons/io";
import { MdVerifiedUser } from "react-icons/md";
import "../../../Style/Registro/verficacionregistro.css"
import { Link, useNavigate } from "react-router-dom";
import {CircularProgress } from '@mui/material';
import { FiAlertCircle } from "react-icons/fi";

export const Verificarregistrousuario = () => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [codigo, setCodigo] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${backendURL}/api/getCodigo`, {
                params: { codigo }
            });
            navigate('/Registroformusuario', { state: { suscripcion: response.data } });
        } catch (error) {
            setError('código de verificacion incorrecto.');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Container component="main" maxWidth="xs">
                <Box sx={{ position: "relative", top: "30px" }}>
                    <Link to="/Registro">
                    <IoIosArrowBack className="iconoback" />
                    </Link>
                </Box>
                <Box sx={{
                    padding: '20px 40px',
                    '@media (max-width: 450px)': {
                        padding: '20px 10px' // Margen reducido para pantallas menores a 450px
                    }

                }}>
                    <section className="contienenverificacionpagina">
                        <div className="iconoseguridad"><MdVerifiedUser /></div>
                        <div className="tituloverificar"><h1>Verificación de registro</h1>
                        </div>
  <strong className="bienveindaverificacion"> ¡Bienvenido al Plan Smart Memorial!</strong>
                        <div className="contieneninformacionverificar">

                             <span>
                             Para completar tu registro y comenzar a
                              disfrutar de todos los beneficios que ofrece
                               nuestro servicio, por favor, ingresa el código
                                que hemos enviado a tu correo electrónico.
                                 Este código es crucial para verificar tu identidad 
                                 y garantizar una experiencia segura y personalizada.
                                  </span>
                                  </div>

                                  <div className="inputverificacionregistro">
                            <label htmlFor="">Ingresa código</label>
                            <input
                                type="text"
                                className="verificacionregistro"
                                value={codigo}
                                onChange={(e) => setCodigo(e.target.value)}
                            />
                        </div>
                        {error && <div className="errorsmessage"> <FiAlertCircle/>{error}</div>}
                        <div className="botondeenviarverificacion">
                            <button onClick={handleSubmit} disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : "Enviar"}
                            </button>
                        </div>
                        <div className="informacionverificacionfinal"><span> Si necesitas ayuda o tienes alguna pregunta, no dudes en contactarnos a
                         <a style={{textDecoration:"none", color:"inherit"}} href="mailto:smartmemorialok@gmail.com">    <strong style={{cursor:"pointer"}}>smartmemorialok@gmail.com</strong></a> </span></div>
                    </section>


                </Box>
            </Container>

        </>
    )
}