import React, { useState } from "react";
import { useAuth } from "../../context/usecontext";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination';
import { BsQrCodeScan } from "react-icons/bs";
import "../../../Style/admi/admiperfil.css"
import { styled } from "@mui/system";
import { ImDownload } from "react-icons/im";


// Estilo personalizado para las celdas de la tabla
const StyledTableCell = styled(TableCell)({
    textAlign:"center",
    fontFamily:"Poppins",
    color: "#585857",
    fontWeight:"bold",
    borderTop:"0px solid #585857",
    borderBottom:"1px solid #585857",
})
const StyledTableCells = styled(TableCell)({
    textAlign:"center",
    fontFamily:"Poppins",
    color: "#585857",
    borderTop:"0px solid #585857",
    borderBottom:"1px solid #585857",
})

const StyledTablePagination = styled(TablePagination)({
    textAlign:"center",
    fontFamily:"Poppins",
    color: "#694B81",
    fontWeight:"600"
   
})

const QrGetFuneraria = ({ funeraria }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const downloadQR = async (fn) => {
        console.log(fn);
        try {
            const response = await axios.post(`${backendURL}/api/downloadQr`, {
                qr: fn.qr,
                id: fn.id
            }, {
                responseType: 'blob' // Especifica que la respuesta es un blob (archivo binario)
            });
    
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = fn.qr; // Nombre del archivo
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
    };
      
    return (
        <div>
            <section className="titulofamilaadjudicadasservicio"> <BsQrCodeScan /><h1>Qr adjudicas al servicio</h1></section>
            <TableContainer component={Paper}>
            <StyledTablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={funeraria.grupo_familiar.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Acciones</StyledTableCell>
                            <StyledTableCell>Nombre Familia</StyledTableCell>
                            <StyledTableCell>QR</StyledTableCell>
                            <StyledTableCell>Estado</StyledTableCell>
                            <StyledTableCell>Usuario</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {funeraria.grupo_familiar
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((fn, index) => (
                                <TableRow key={index}>
                                    <StyledTableCells component="th" scope="row">
                                        <button  className="dowlandqradmi" onClick={() => downloadQR(fn)}> <ImDownload /> </button>
                                    </StyledTableCells>
                                    <StyledTableCells>{fn.nombre_familia || 'No Asignada'}</StyledTableCells>
                                    <StyledTableCells>
                                        {funeraria.logo && (
                                            <img
                                                src={`${backendURL}/imagenes/qr/${fn.qr}`}
                                                alt="smart memorial"
                                                style={{ maxWidth: '150px', maxHeight: '100%', width: 'auto', height: 'auto' }}
                                            />
                                        )}
                                    </StyledTableCells>
                                    <StyledTableCells>{fn.estado === '0' ? 'Inactivo' : fn.estado === '1' ? 'Activo' : 'Estado Desconocido'}</StyledTableCells>
                                    <StyledTableCells>
                                        {fn.user ? (
                                            fn.user.map((user, index) => (
                                                <div key={index}>{user.name}</div>
                                            ))
                                        ) : (
                                            "No hay usuario"
                                        )}
                                    </StyledTableCells>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
               
            </TableContainer>
        </div>
    );
};

export default QrGetFuneraria;
