import React from 'react';
import { useState } from 'react'
import "../../../../Style/usuario/crear-familiar.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Container, CircularProgress, } from "@mui/material";

/* elmentos */

import Box from "@mui/material/Box";
import { FiAlertCircle } from "react-icons/fi";


/* imagenes */
import logomemorialdos from "../../../../Image/generales/grupo-familia-2.png";





export const Usuariocrearfamilia = () => {
  const [error, setError] = useState('');
  const [groupName, setGroupName] = useState('');
  const location = useLocation();
  const grupoFamiliar = location.state?.familia; 
  const ClientToken = location.state?.user; 
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleSaveClick = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${backendURL}/api/updatedFamiliarName/${grupoFamiliar.id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${ClientToken.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nombre_familia: groupName }),
      });
  
      if (response.ok) {
        setGroupName('');
        const updatedGrupoFamiliar = { ...grupoFamiliar, nombre_familia: groupName };
        // Navegar a la siguiente página y pasar la información actualizada
        navigate('/crearmemorial', { state: { user: ClientToken, familia: updatedGrupoFamiliar }});
      } else {
        const data = await response.json();
        setError(data.message || 'Error al actualizar el nombre del grupo familiar');
      }
    } catch (error) {
      console.error('Error updating group name:', error);
      setError('Error al actualizar el nombre del grupo familiar');
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleGroupNameChange = (event) => {
    setGroupName(event.target.value);
    setError(''); // Limpiar el mensaje de error cuando se comienza a escribir
  };

  const handleNextPage = async () => {
    if (!groupName.trim()) {
      setError('Por favor, escribe el nombre del grupo familiar');
      return;
    }
    handleSaveClick();
  };

  return (
    <Container component="main" maxWidth="xs">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems:'center'
              }}
            >
              <div className="titulo-crear-grupo-memorial">
                <h1>Le damos la {ClientToken.usergenero === 'Femenino' ? 'bienvenida' : ClientToken.usergenero === 'Masculino' ? 'bienvenido' : 'bienvenides'},</h1>
                <h1><strong> {ClientToken.usernombre} </strong></h1>
                <h1><strong> {ClientToken.userapellido} </strong></h1>
              </div>
              <div className='contiene-imagen-grupo-memorial'>
                <img src={logomemorialdos}  alt='smart memorial' className='logogrupofamiliar'/>
                <h1> Crear Grupo Familiar </h1>
              </div>
              <div className="creacion-input-memorial">
                <div className="creacion-nombre-grupo-familiar">
                  <label className="titulo-inupt-familia">Nombre</label>
                  <input
                    name="familia"
                    className={`input-nombre-familia ${error && 'input-error'}`}
                    placeholder="Ej: Fernandéz Rojas"
                    value={groupName}
                    onChange={handleGroupNameChange}
                  />
                  {error && <p className="errormessage"><FiAlertCircle />{error}</p>}
                  <div className="terminos">
                    <FiAlertCircle /><span>El grupo familiar puede contener hasta 5 memoriales.</span>
                  </div>
                </div>
              </div>
              <div className="contienen-botones">
                <div className="boton-registro">
                  <button onClick={handleNextPage} disabled={isLoading}>  {isLoading ? <CircularProgress size={24} /> : "Crear"}</button>
                </div>
              </div>
            </Box>
            </Container>
        
  );
}