import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import '../../../../Style/Registro/tipomemorialpagina.css'

export const Memorialfull = () => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [formValues, setFormValues] = useState({
        nombres: '',
        apellidos: '',
        correo_electronico: '',
        telefono: '+569',
        direccion: ''
    });
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };


    const sendForm = () => {
        setLoading(true);
        axios.post(`${backendURL}/api/sendmemorialfull`, formValues)
            .then(response => {
                setLoading(false);
                setAlertMessage('Formulario enviado con éxito');
                handleOpen();
                // Limpiar los campos del formulario
                setFormValues({
                    nombres: '',
                    apellidos: '',
                    correo_electronico: '',
                    telefono: '+569',
                    direccion: ''
                });
                // Cerrar el modal después de 3 segundos
                setTimeout(() => {
                    handleClose();
                }, 3000);
            })
            .catch(error => {
                setLoading(false);
                setAlertMessage('Error al enviar el formulario');
                handleOpen();
                // Cerrar el modal después de 3 segundos
                setTimeout(() => {
                    handleClose();
                }, 3000);
            });
    };

    return (
        <Container component="main" maxWidth="xs" >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',

                }}
            >
                <section>
                    <Link to="/solicitarmemorial">
                    <IoIosArrowBack  className="icononback"/>
                    </Link>
                </section>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <section className="titulomemorialselecion"> <h1> Memorial Full</h1></section>
                    <section className="contieneniformacionmemorialselecion">
                        <span>Memorial full: este servicio contempla además del memorial digital,
                             la entrega de una placa metálica QR resistente al interperie, 
                             dónde podrá adherir a la lápida o colgar en un ánfora respectivamente. 
                        </span>
                    </section>
                    <section className="contieneinformacionmemorialselecion">
                            <div className="recuadroinfomemorial">
                                <span>Placa para lápida + servicio digital</span>
                                <div className="preciosmartmemorial">
                                    <h1>$8.990</h1> <span>/ mantención anual</span>
                                </div>
                            </div>
                            <form style={{width:'100%'}}  onSubmit={(e) => { e.preventDefault(); sendForm(); }}>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="nombres"> Nombres</label>
                                    <input type="text" id="nombres" className="inputgenerarcompramemorial"  name="nombres" value={formValues.nombres} onChange={handleInputChange}   />
                                </div>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="apellidos"> Apellidos</label>
                                    <input type="text"id="apellidos"  className="inputgenerarcompramemorial" name="apellidos" value={formValues.apellidos} onChange={handleInputChange} />
                                </div>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="correo_electronico"> Correo electrónico</label>
                                    <input type="text" id="correo_electronico" className="inputgenerarcompramemorial"  name="correo_electronico" value={formValues.correo_electronico} onChange={handleInputChange} />
                                </div>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="telefono">Número de celular</label>
                                    <input type="text" id="telefono" className="inputgenerarcompramemorial"  name="telefono" value={formValues.telefono} onChange={handleInputChange} />
                                </div>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="direccion">Dirección de despacho</label>
                                    <input type="text" id="direccion" className="inputgenerarcompramemorial"  name="direccion" value={formValues.direccion} onChange={handleInputChange} />
                                </div>

                                <div className='botonsolicitarmemorial'>
                                    <button type="submit"> Solicitar </button>
                                </div>
                                <div className='informcinocompramemeorial'>
                                    <span>El equipo de Smart Memorial </span>
                                    <span> se pondrá en contacto con usted</span>
                                </div>
                            </form>
                            </section>

                </Box>
            </Box>
            <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress color="secondary" />
      </Backdrop>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, border: '0px solid #000',  }}>
                    <Alert severity={alertMessage.includes('éxito') ? 'success' : 'error'}>
                        {alertMessage}
                    </Alert>
                </Box>
            </Modal>
        </Container>
    )
}