import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useAuth } from "../../context/usecontext";
import TextField from '@mui/material/TextField';
import "../../../Style/admi/admiperfil.css"
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import useMediaQuery from '@mui/material/useMediaQuery';


const QrStore = ({ idFuneraria, fetchData, handleClose }) => {
    const { adminToken } = useAuth();
    const [cantidad, setCantidad] = useState(""); // Estado para la cantidad
    const [openAlert, setOpenAlert] = useState(false);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const handleGrupoFamiliarStore = async () => {
        handleOpenAlert();
        try {
            const response = await fetch(`${backendURL}/api/grupoFamiliarStore`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${adminToken}`
                },
                body: JSON.stringify({
                    id_funeraria: idFuneraria,
                    cantidad: cantidad
                })
            });
            fetchData(); // Llamar a la función de actualización en el componente padre
            handleClose();
            if (response.ok) {
                const data = await response.json();

                handleOpenAlert(); 
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
        finally { }
    };


    const handleOpenAlert = () => {
        setOpenAlert(true);
    };


    useEffect(() => {
        if (openAlert) {
            // Establecer un temporizador para cerrar el modal después de dos segundos
            const timer = setTimeout(() => {
                handleCloseAlert();
                handleClose();
            }, 2000); // 2000 milisegundos = 2 segundos

            // Limpiar el temporizador al desmontar el componente o al actualizar openAlert
            return () => clearTimeout(timer);
        }
    }, [openAlert]);

    const handleCloseAlert = () => {
        setOpenAlert(false);
        handleClose(); // Cerrar todo el componente modal y el formulario
    };
    // Esta useEffect se ejecutará cuando openAlert cambie a false
    useEffect(() => {
        if (!openAlert) {
            fetchData(); // Actualiza los datos
        }
    }, [openAlert, fetchData]);
    const isSmallScreen = useMediaQuery('(max-width: 400px)');

    // Establecer el valor de maxWidth en función del tamaño de la pantalla
    const maxWidth = isSmallScreen ? 200 : 400;

    const handleSubmit = (e) => {
        e.preventDefault();
        handleGrupoFamiliarStore();
    };

    return (
        <div className="cantidad">
            <section className="furtherfurther">Ingresar  Cantidad de Qr para el Servicio</section>


            <form className="cantidadenviar" onSubmit={handleSubmit}>
                <TextField
                    className="inputcantidadqr"
                    label="Cantidad"
                    type="number"
                    value={cantidad}
                    onChange={(e) => setCantidad(e.target.value)}
                    fullWidth
                    required
                />
                <button className="enviarcantidadqr" type="submit">Enviar</button>
                <Modal
                    open={openAlert}
                    onClose={handleCloseAlert}
                    aria-labelledby="alert-modal-title"
                    aria-describedby="alert-modal-description"
                >
                    <Box
                        sx={{
                            position: "absolute",
                            width: '100%',
                            maxWidth: maxWidth,
                            bgcolor: "transparent",
                            border: "0px",
                            p: 4,
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        <Alert severity="success" sx={{ width: '100%' }}>
                            Cantidades de codigos Qr generados.
                        </Alert>
                    </Box>
                </Modal>
            </form>
        </div>
    );
};

export default QrStore;