import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../context/usecontext";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import useMediaQuery from '@mui/material/useMediaQuery';
import { TextField, Button, Grid } from '@mui/material';
import "../../../Style/admi/admiperfil.css"

const StyledForm = styled('form')({
    '& .MuiTextField-root': {
        marginBottom: '1rem',
    },
});

export const FunerariaUpdate = ({ funeraria, fetchData, handleClose }) => {


    const { adminToken } = useAuth();
    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    const [direccion, setDireccion] = useState("");
    const [mensajeAnual, setMensajeAnual] = useState("");
    const [web, setWeb] = useState("");
    const [logo, setLogo] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const [openAlert, setOpenAlert] = useState(false);

    // Este efecto se ejecutará cuando el componente se monte y establecerá los valores del estado con los datos existentes de la funeraria
    useEffect(() => {
        if (funeraria) {
            setNombre(funeraria.nombre);
            setEmail(funeraria.email);
            setDireccion(funeraria.direccion);
            setMensajeAnual(funeraria.mensaje_anual);
            setWeb(funeraria.web);
        }
    }, [funeraria]); // Se ejecutará cada vez que funeraria cambie

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("nombre", nombre);
        formData.append("email", email);
        formData.append("direccion", direccion);
        formData.append("mensaje_anual", mensajeAnual);
        formData.append("web", web);
        formData.append("logo", logo);
        formData.append("id", funeraria.id);
        try {
            const response = await axios.post(`${backendURL}/api/funerariasUpdate`, formData, {

                headers: {
                    'Authorization': `Bearer ${adminToken}`,
                    'Content-Type': 'multipart/form-data',
                },

            });
            if (response.status === 200) {
                setOpenAlert(true);
                if (fetchData) {
                    fetchData();
                }
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
        }
        finally { fetchData() };
    };
   

    const handleCloseAlert = () => {
        setOpenAlert(false);
        handleClose();
    };

    useEffect(() => {
        if (openAlert) {
            // Establecer un temporizador para cerrar el modal después de dos segundos
            const timer = setTimeout(() => {
                handleCloseAlert();
            }, 2000); // 2000 milisegundos = 2 segundos

            // Limpiar el temporizador al desmontar el componente o al actualizar openAlert
            return () => clearTimeout(timer);
        }
    }, [openAlert]);

     // Esta useEffect se ejecutará cuando openAlert cambie a false
     useEffect(() => {
        if (!openAlert) {
            fetchData(); // Actualiza los datos
        }
    }, [openAlert, fetchData]);

    const isSmallScreen = useMediaQuery('(max-width: 400px)');
    // Establecer el valor de maxWidth en función del tamaño de la pantalla
    const maxWidth = isSmallScreen ? 200 : 400;

    return (
        <section>
            <section className="tituloeditarservicio">
                <h1>Editar Servicio Smart Memorial</h1>
            </section>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Nombre"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Dirección"
                            value={direccion}
                            onChange={(e) => setDireccion(e.target.value)}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Mensaje Anual"
                            value={mensajeAnual}
                            onChange={(e) => setMensajeAnual(e.target.value)}
                            multiline
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Sitio Web"
                            value={web}
                            onChange={(e) => setWeb(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <input
                            type="file"
                            accept="image/png"
                            onChange={(e) => setLogo(e.target.files[0])}
                        />
                    </Grid>
                </Grid>
                <Modal
                    open={openAlert}
                    onClose={handleCloseAlert}
                    aria-labelledby="alert-modal-title"
                    aria-describedby="alert-modal-description"
                    sx={{ zIndex: 9999999999999999999 }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            width: '100%',
                            maxWidth: maxWidth,
                            bgcolor: "transparent",
                            border: "0px",
                            p: 4,
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 9999999999999999999
                        }}
                    >
                        <Alert severity="success" sx={{ width: '100%', zIndex: 9999999999999999999 }}>
                            Servicio Smart Memorial editado correctamente.
                        </Alert>
                    </Box>
                </Modal>
                <button className="buttonenviarserviciocreado" type="submit">Enviar</button>
            </form></section>
    );
}
