import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import axios from "axios";
import { Container ,CircularProgress } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { PerfilUsuario } from './perfilUsuario';
import { UpdatePassword } from './updatePassword';
import { Suscripcionusuario } from './suscripcionusuario';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from '@mui/system';
import"../../../../Style/usuario/miperfil.css"
import { BiBorderRadius } from 'react-icons/bi';

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  borderRadius:"29px",
  minHeight:"0px",
  color:"#694B81",
  fontWeight:"500",
  minWidth:"135px",
  fontFamily: 'Poppins, sans-serif',
  '&.Mui-selected': {
    backgroundColor: "#694B81",
    color: "#fff",

  },
  '@media (max-width: 450px)': {
    minWidth:"100px",
    fontSize:"0.7rem",
   
  },
  '@media (max-width: 350px)': {
    minWidth:"80px",
    fontSize:"0.7rem",
  },
  '@media (max-width: 317px)': {
    minWidth:"50px",
    fontSize:"0.65rem",
  },
  '@media (max-width: 303px)': {
    minWidth:"30px",
    fontSize:"0.6rem",
  },
  '@media (max-width: 290px)': {
    minWidth:"20px",
    fontSize:"0.55rem",
  },
  '@media (max-width: 276px)': {
    minWidth:"30px",
    fontSize:"0.51rem",
  },
  '@media (max-width: 265px)': {
   
    fontSize:"0.48rem",
  },

}));
const StyledTabList = styled(TabList)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',

  '& .MuiTabs-indicator': {
    
    backgroundColor: 'transparent', // Fondo transparente
  },
}));

export const Perfilcliente = () => {
  const location = useLocation();
  const ClientToken = location.state.user; // Obtener la información del usuario desde las props
  const grupoFamiliar = location.state.familia; 
    const [value, setValue] = React.useState('1');
    const [logoUrl, setLogoUrl] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


   /* funeraria Logo traer */
 useEffect(() => {
  const fetchFuneraria = async () => {
    try {
      if (grupoFamiliar && grupoFamiliar.id_funeraria) {
        const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
        const funerariaData = response.data;
        
        if (funerariaData && funerariaData.logo) {
          setLogoUrl(funerariaData.logo);
        }
      } else {
        console.error('Group family or funerary ID is missing');
      }
    } catch (error) {
      console.error('Error fetching funeraria data:', error);
    }
  };

  fetchFuneraria();
}, [grupoFamiliar]); 


  return (
    <section className="contienenpaginainiciousuario">
     {logoUrl && (
          <div className="logofunerariamemorial">
            <img
              src={`${backendURL}/${logoUrl}`}
              alt="Logo de la funeraria"
              className="logofun"
              
            />
          </div>
        )}
    <Container  component="main" maxWidth="xs" sx={{'@media (max-width: 700px)': {
            marginTop:"50px",
          
          } }}>

      <section className='titulomiperfil'>
        <h1>Mi perfil</h1>
      </section>
    <Box sx={{ typography: 'body1' }}>
      <TabContext value={value} sx={{ width:"100%",  alignItems:"center", justifyContent:"center"}}>
        <Box sx={{textAlign:"center", display:"flex", alignItems:"center", justifyContent:"center", borderColor: 'divider' }}>
          <StyledTabList onChange={handleChange} sx={{ width:"100%",  alignItems:"center", justifyContent:"center"}} >
            <StyledTab label="Mis Datos" value="1"  />
            <StyledTab label="Privacidad" value="2"  />
            <StyledTab label="Suscripción" value="3"  />
          </StyledTabList>
        </Box>
        <TabPanel value="1"><PerfilUsuario  /></TabPanel>
        <TabPanel value="2"><UpdatePassword/></TabPanel>
        <TabPanel sx={{width:"100%",padding:"0px", marginTop:"25px"}} value="3"><Suscripcionusuario/></TabPanel>
      </TabContext>
    </Box>
    </Container>
    </section>
  );
}