import React from "react";
import axios from 'axios';
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import "../../../../Style/usuario/editarmemorial.css"
import { IoIosArrowBack } from "react-icons/io";
import { RxCalendar } from "react-icons/rx";
import { ImSearch } from "react-icons/im";
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { PiScrollFill } from "react-icons/pi";
import { FiAlertCircle } from "react-icons/fi";
import { RiFileEditFill } from "react-icons/ri";
import { FaPhotoVideo } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FiPlusCircle } from "react-icons/fi";
import { FaInfoCircle } from "react-icons/fa";
import { BsTrash3Fill } from "react-icons/bs";
import Modal from '@mui/material/Modal';
import dayjs from 'dayjs';
import ReactPlayer from 'react-player';
import { CircularProgress } from '@mui/material';
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';



/* imagenes */

import logomemorialdos from "../../../../Image/generales/grupo-familia-2.png";
import editar from "../../../../Image/generales/descripcion-foto.png";
import crear from "../../../../Image/generales/crear-memorial.png";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel() {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}




export const Editarmemorial = () => {
  const location = useLocation();
  const navigate = useNavigate();
   const ClientToken = location.state.user;  // Obtener la información del usuario desde las props
  const grupoFamiliar = location.state.familia;
  const memorialId = location.state.memorial;
  const [showCreateOnlyImageConfirmation, setShowCreateOnlyImageConfirmation] = useState(false);
  const [imagenPreview, setImagenPreview] = useState(null);
  const [imagenesData, setImagenesData] = useState([]);
  const [logoUrl, setLogoUrl] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false);
  const [video, setVideo] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState(null);
  const [comentarios, setComentarios] = useState([]);
  const [videos, setVideos] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [openModals, setOpenModals] = useState(false);
  const [openModalseliminar, setOpenModalseliminar] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const[enviandoAlertprogress, setenviandoAlertprogress] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [historia, setHistoria] = useState(null)
    const [progress, setProgress] = useState(0);

  const [formData, setFormData] = useState({
    nombre: '',
    titulo: '',
    descripcion: '',
    imagen: null
  });
  const [memorialData, setMemorialData] = useState({
    id: '',
    nombre: '',
    fecha_nacimiento: '', // Valor inicial vacío o cualquier otro valor válido
    fecha_defuncion: '',
    cementerio: '',
    carpeta_perfil: '',
    imagen_perfil: '',
    historia: historia,
 galeria: []
  
  });

  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const capitalizeFirstLetter = (string) => {
    if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleGoBack = () => {
    const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorialData };
    navigate('/usuariomemoriales', { state });
  };



  /* memorial */
  const fetchMemorialData = async (id) => {
    try {
      const response = await axios.get(`${backendURL}/api/getMemorialId/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ClientToken.token}`,
        }
      });
      setMemorialData(response.data);
      setLoading(false);
      setHistoria(response.data.historia)
    } catch (error) {
      console.error('Error fetching memorial data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMemorialData(memorialId);
  }, []);


  /* info del memorial editar */
  const handleChange = (value) => {
    setHistoria(value);
  };
  

  const handleMemorialUpdate = async (fieldName, value) => {
    try {
      // Construir formData para enviar al backend
      const formData = new FormData();

      // Copiar todos los campos actuales al formData
      Object.entries(memorialData).forEach(([key, val]) => {
        formData.append(key, val);
      });

      // Si el campo es una fecha, formatearlo correctamente
      if (fieldName === 'fecha_nacimiento' || fieldName === 'fecha_defuncion') {
        const date = new Date(value);
        const formattedDate = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`;
        formData.set(fieldName, formattedDate);
      } else if (fieldName === 'imagen_perfil') {
        // Si el campo es imagen_perfil, adjuntar el file al formData
        formData.set(fieldName, value);
      } else {
        // De lo contrario, adjuntar el nuevo valor al formData
        formData.set(fieldName, value);
      }

      // Enviar la solicitud al backend
      const response = await axios.post(`${backendURL}/api/memorialUpdate/${memorialData.id}`, formData, {
        headers: {
          Authorization: `Bearer ${ClientToken.token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      // Actualizar el memorialData con la respuesta del servidor (opcional)
      setMemorialData(prevState => ({ ...prevState, [fieldName]: value }));
    } catch (error) {
      console.error('Error al actualizar el memorial:', error);
    }
  };



// Manejar cambios en los campos del formulario
const handleFieldChange = (fieldName, value) => {
  setMemorialData(prevState => ({
    ...prevState,
    [fieldName]: value
  }));
};

  /* funeraria Logo traer */
  useEffect(() => {
    const fetchFuneraria = async () => {
      try {
        if (grupoFamiliar && grupoFamiliar.id_funeraria) {
          const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
          const funerariaData = response.data;

          if (funerariaData && funerariaData.logo) {
            setLogoUrl(funerariaData.logo);
          }
        } else {
          console.error('Group family or funerary ID is missing');
        }
      } catch (error) {
        console.error('Error fetching funeraria data:', error);
      }
    };
    fetchFuneraria();
  }, [grupoFamiliar]);


  /* imagen selecion perfil */

  useEffect(() => {
    if (memorialData.imagen_perfil) {
      setImagenPreview(`${backendURL}/imagenes/memoriales/${memorialData.carpeta_perfil}/${memorialData.imagen_perfil}`);
    }
  }, [memorialData.imagen_perfil]);

  const handleFotoPerfilChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setImagenPreview(reader.result);
        handleMemorialUpdate('imagen_perfil', file);
      };
      reader.readAsDataURL(file);
    }
  };


  /* galeria */

  const handleInputChange = (index, name, value) => {
    const list = [...imagenesData];
    list[index][name] = value;
    setImagenesData(list);
  };

  const handleDescriptionButtonClick = (index) => {
    setSelectedImageIndex(index);
    const selectedImageData = memorialData.galeria[index];
    setImagenesData([{
      titulo: selectedImageData.titulo || '',
      descripcion: selectedImageData.descripcion || '',
      fecha: selectedImageData.fecha || ''
    }]);
    setModalOpen(true);
  };

  const handleEditImage = async () => {
    setLoading(true);
    try {
      setenviandoAlert(true);
      setLoading(true);
        const imageId = memorialData.galeria[selectedImageIndex].id;
        const updatedImageData = {
            id: imageId,
            titulo: imagenesData[0]?.titulo || '',
            descripcion: imagenesData[0]?.descripcion || '',
            fecha: imagenesData[0]?.fecha || ''
        };

        
        setLoading(true);
        const response = await axios.post(
            `${backendURL}/api/galeriaimagenUpdate`,
            updatedImageData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${ClientToken.token}`
                }
            }
        );

        
        setLoading(false);
        handleModalClose(true);
        setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su imagen en galeria fue editada con éxito.')
      await fetchMemorialData(memorialId);
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al editar la imagen, vuelva a intentar.');
    }
    finally{
      setenviandoAlert(false);
 }

 // Simulación de tiempo de carga
 setTimeout(() => {
     handleCloseModal();
 }, 3000);
 // Simulación de tiempo de carga
 setTimeout(() => {
     setSuccessAlertOpen(false);
     setErrorAlertOpen(false);
 }, 2000);
};


  const handleImageChange = (e) => {
    const { files } = e.target;
    const imagenData = {
      titulo: '',
      descripcion: '',
      fecha: '',
      imagen: files[0]
    };
    setImagenesData([imagenData]);
    setSelectedImageIndex(0);
    setShowCreateOnlyImageConfirmation(true);
  };

  const handleAddImage = () => {
    setImagenesData([...imagenesData, { titulo: '', descripcion: '', fecha: '', imagen: null }]);
  };

  const handleRemoveImage = async (index) => {
    try {
      setenviandoAlert(true);
      // Obtiene el ID de la imagen a eliminar
      const imageId = memorialData.galeria[index].id;

      // Envía una solicitud POST al backend para eliminar la imagen
      await axios.post(
        `${backendURL}/api/galeriaDelete`,
        { id: imageId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ClientToken.token}`
          }
        }
      );

      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su imagen en galeria fue eliminada con éxito.')
      const list = [...imagenesData];
      list.splice(index, 1);
      setImagenesData(list);
      await fetchMemorialData(memorialId);
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al eliminar la imagen, vuelva a intentar.');
    }
    finally{
      setenviandoAlert(false);
 }

 // Simulación de tiempo de carga
 setTimeout(() => {
     handleCloseModal();
 }, 3000);
 // Simulación de tiempo de carga
 setTimeout(() => {
     setSuccessAlertOpen(false);
     setErrorAlertOpen(false);
 }, 2000);
};


  const handleCreateOnlyImageConfirmation = async () => {
    

    const formData = new FormData();
    formData.append('id_memorial', memorialId);

    // Agregar imagen al formulario
    if (selectedImageIndex !== null) {
      const imagenData = imagenesData[selectedImageIndex];
      const formattedFecha = imagenData.fecha ? dayjs(imagenData.fecha, 'YYYY-MM-DD').format('YYYY/MM/DD') : '';
      formData.append('imagenes[0][imagen]', imagenData.imagen);
      formData.append('imagenes[0][titulo]', imagenData.titulo);
      formData.append('imagenes[0][descripcion]', imagenData.descripcion);
      formData.append('imagenes[0][fecha]', formattedFecha);
    }

    try {
      setenviandoAlert(true);
      
      const responseGaleria = await axios.post(`${backendURL}/api/galeriaStore`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${ClientToken.token}`
        },
      });

      
      handleModalClose(true);
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su imagen en galeria fue creado con éxito.')
      await fetchMemorialData(memorialId);
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al crear la imagen para galeria, vuelva a intentar.');
    }
    finally{
      setenviandoAlert(false);
      
 }

 // Simulación de tiempo de carga
 setTimeout(() => {
     handleCloseModal();
 }, 3000);
 // Simulación de tiempo de carga
 setTimeout(() => {
     setSuccessAlertOpen(false);
     setErrorAlertOpen(false);
 }, 2000);
};

  const handleModalClose = () => {
    setModalOpen(false);
    setShowCreateOnlyImageConfirmation(false);
    setImagenesData([]);
    setSelectedImageIndex(null);
  };


  /* video */

  const enviarVideoAlServidor = async (formData) => {
    try {
      setenviandoAlertprogress(true);
      setenviandoAlert(true);
      setUploading(true);
      setProgress(0); // Inicializar el progreso a 0
  
      const simulateUploadProgress = () => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(progressInterval);
            return 100;
          }
          return prevProgress + 10;
        });
      };
  
      const progressInterval = setInterval(simulateUploadProgress, 800);
  
      const response = await axios.post(
        `${backendURL}/api/updateMemorial/${memorialData.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${ClientToken.token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      await fetchMemorialData(memorialId);
      setShowSuccessMessage(true);
      setUploading(false);
      setenviandoAlertprogress(false);
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su video fue creado con éxito.');
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al crear el video, vuelva a intentar.');
    } finally {
      setUploading(false);
      setenviandoAlert(false);
    }
    
    // Simulación de tiempo de carga
    setTimeout(() => {
      handleCloseModal();
    }, 3000);
    // Simulación de tiempo de carga
    setTimeout(() => {
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
    }, 2000);
  };
  
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    setFile(file);
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.src = URL.createObjectURL(file);
  
    video.onloadedmetadata = () => {
      if (video.duration > 30.1) {
        setShowAlert(true);
        URL.revokeObjectURL(video.src);
      } else {
        setVideo(URL.createObjectURL(file));
        setShowAlert(false);
  
        const formData = new FormData();
        formData.append('video', file);
        formData.append('memorialId', memorialData.id);
  
        // Verificar si el video ya está cargado antes de enviarlo al servidor
        if (!videoLoaded) {
          // Llama a la función para enviar el video al servidor directamente
          enviarVideoAlServidor(formData);
          // Establece el estado para indicar que el video se ha cargado
          setVideoLoaded(true);
        }
      }
    };
  
    setShowFileInput(false);
  };

  const handleVideoClick = () => {
    if (video) {
      // Si hay un video, simplemente limpiamos la selección actual
      setVideo(null);
      setShowAlert(false);
      setShowSuccessMessage(false);
    } else {
      // Si no hay video, simulamos hacer clic en el input de tipo file
      setShowSuccessMessage(false);
      const fileInput = document.getElementById('filevideoinput');
      if (fileInput) {
        fileInput.click();
      } else {
        console.error('Elemento con ID filevideoinput no encontrado.');
      }
    }
  };
  
  useEffect(() => {
  if (memorialData.video) {
    const uniqueUrl = `${backendURL}/video/${memorialData.video}?t=${new Date().getTime()}`;
    setVideo(uniqueUrl);
  } else {
    setVideo(null);
  }
}, [memorialData, backendURL]);



  /* mensaje */

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { nombre, titulo, descripcion, imagen } = formData;

    const formDataToSend = new FormData();
    formDataToSend.append('titulo', titulo);
    formDataToSend.append('creador', nombre);
    formDataToSend.append('mensaje', descripcion);
    formDataToSend.append('imagen', imagen);
    formDataToSend.append('id_memorial', memorialId);

    try {
      setLoading(true);
      setenviandoAlert(true);
      const response = await axios.post(`${backendURL}/api/comentarioStore`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${ClientToken.token}`
        }
      });

      await axios.post(`${backendURL}/api/comentarioState`, {
        id: response.data.id, visible: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ClientToken.token}`
        }
      });

      setLoading(false);
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su mensaje fue creado con éxito.')
      // Actualizar comentarios en memorialData
      const updatedComentarios = [...memorialData.comentarios, response.data];
      setMemorialData({ ...memorialData, comentarios: updatedComentarios });
      setFormData({
        nombre: '',
        titulo: '',
        descripcion: '',
        imagen: null
      });
      handleCloseModal(true);
     await fetchMemorialData(memorialId);

    } catch (error) {
      setErrorAlertOpen(true);
            setAlertMessage('Error al enviar el mensaje, vuelva a intentar.');
    }
    finally{
      setenviandoAlert(false);
 }

 // Simulación de tiempo de carga
 setTimeout(() => {
     handleCloseModal();
 }, 3000);
 // Simulación de tiempo de carga
 setTimeout(() => {
     setSuccessAlertOpen(false);
     setErrorAlertOpen(false);
 }, 2000);
  };

  
  const handleDeleteComment = async (id) => {
    try {
      setenviandoAlert(true);
      setLoading(true);
      await axios.post(
        `${backendURL}/api/comentarioDelete`,
        { id: id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ClientToken.token}`
          }
        }
      );
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su mensaje fue eliminado con éxito.')
      setLoading(false);
      handleCloseModals();
      const updatedComentarios = comentarios.filter(comentario => comentario.id !== id);
      setComentarios(updatedComentarios);

      setFormData({
        nombre: '',
        titulo: '',
        descripcion: '',
        imagen: null
      });
     await fetchMemorialData(memorialId);
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al eliminar el mensaje, vuelva a intentar.');
    }
    finally{
      setenviandoAlert(false);
 }

 // Simulación de tiempo de carga
 setTimeout(() => {
     handleCloseModal();
 }, 3000);
 // Simulación de tiempo de carga
 setTimeout(() => {
     setSuccessAlertOpen(false);
     setErrorAlertOpen(false);
 }, 2000);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setenviandoAlert(false);
    setenviandoAlertprogress(false);
  };


  const handleCloseModals = () => {
    setOpenModals(false);
    setCommentToDelete(null);
  };

  /* fondo */

  useEffect(() => {
    const fondo = memorialData.fondo;
    setSelectedOption(fondo);
  }, [memorialData]);


  const handleChangeFondo = async (event) => {
    const newFondo = event.target.value;
    setSelectedOption(newFondo);
    try {

      const response = await axios.post(
        `${backendURL}/api/updateMemorial/${memorialId}`,
        { fondo: newFondo },
        {
          headers: {
            'Authorization': `Bearer ${ClientToken.token}`
          }
        }
      );
      setMemorialData({ ...memorialData, fondo: newFondo });
    } catch (error) {
      console.error('Error al editar el memorial:', error);
    }
  };
/* eliminar memorial */

const handleOpenModalseliminar = () => {
  setOpenModalseliminar(true);
};

const handleCloseModalseliminar = () => {
  setOpenModalseliminar(false);
};

const handleEliminarMemorial = async () => {
  try {
    setLoading(true);
    setenviandoAlert(true);
    await axios.post(`${backendURL}/api/eliminarMemorial/${memorialId}`, {}, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ClientToken.token}`,
      }
    });
    setenviandoAlert(false);
    setLoading(false);
    setOpenModalseliminar(false);
    handleClickfinal(true) 
    setSuccessAlertOpen(true);
      setAlertMessage('El memorial fue eliminado con éxito.')// Redirige a una página de éxito o actualiza el estado
  } catch (error) {
    setErrorAlertOpen(true);
      setAlertMessage('Error al eliminar el memorial, vuelva a intentar.');
    }
    finally{
      setenviandoAlert(false);
 }

 // Simulación de tiempo de carga
 setTimeout(() => {
     handleCloseModal();
 }, 3000);
 // Simulación de tiempo de carga
 setTimeout(() => {
     setSuccessAlertOpen(false);
     setErrorAlertOpen(false);
 }, 2000);
  };

  /* botones */
  const handleClick = () => {
    const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorialData};
    navigate('/demomemorialvista', { state });
  };

  const handleClickfinal = () => {
    const state = { user: ClientToken, familia: grupoFamiliar,  memorial: memorialData};
    navigate('/usuariomemoriales', { state });
  };

  return (
    <section className="contienenpaginainiciousuario">

      {logoUrl && (
        <div className="logofunerariamemorial">
          <img
            src={`${backendURL}/${logoUrl}`}
            alt="Logo de la funeraria"
            className="logofun"

          />
        </div>
      )}
      <Container  component="main" maxWidth="xs">
        <Box sx={{
          padding: '20px 0px',
          '@media (max-width: 450px)': {
            padding: '20px 10px' // Margen reducido para pantallas menores a 450px
          }

        }}>


          <section className="botoncloseeditar" onClick={handleGoBack}> <IoIosArrowBack /> </section>
          <section className="contieneninfomemorial">

            <div className="titulopartedos">
              <img src={crear} alt="smart memorial" />
              <h1 className="titulo-memoria">Editar Memorial</h1>
            </div>

            <form >
              <div className="inputregistorform">
                <label>Nombre del fallecido</label>
                <div className="inputnombreeditfallecido">
                  <input className="inputeditregistro " name="nombre"
                    value={memorialData.nombre}
                    onChange={(e) => handleFieldChange('nombre', e.target.value)}
            onBlur={(e) => handleMemorialUpdate('nombre', e.target.value)}
                    id="name" />
                </div>
              </div>
              <div className="inputregistorform">
                <label htmlFor="editfecha">Fecha de Nacimiento</label>
                <div className="contienencalendarioperfil">
                  <input
                    type="date"
                    className="inputeditregistro "
                    name='fecha_nacimiento'
                    value={memorialData.fecha_nacimiento}
                    onChange={(e) => handleFieldChange('fecha_nacimiento', e.target.value)}
                    onBlur={(e) => handleMemorialUpdate('fecha_nacimiento', e.target.value)}
                  />
 <div className="calendarioicono">
                            <RxCalendar className="iconoperfilcalendario" />
                            </div>
                </div>

              </div>
              <div className="inputregistorform">
                <label htmlFor="editfecha">Fecha de Fallecimiento</label>
                <div className="contienencalendarioperfil">
                  <input
                    type="date"
                    className="inputeditregistro "
                    name='fecha_defuncion'
                    value={memorialData.fecha_defuncion}
                    onChange={(e) => handleFieldChange('fecha_defuncion', e.target.value)}
                    onBlur={(e) => handleMemorialUpdate('fecha_defuncion', e.target.value)}
                  />
                  <div className="calendarioicono">
                            <RxCalendar className="iconoperfilcalendario" />
                            </div>
                </div>

              </div>

              <div className="inputregistorform">
                <label>Cementerio (opcional)</label>
                <div className="inputeditcementerio">

                  <div>
                    <input
                      type='text'
                      className="inputeditregistro "
                      name="cementerio"
                      id="cementerio"
                      placeholder="Buscar cementerios"
                      value={memorialData.cementerio}
                      onChange={(e) => handleFieldChange('cementerio', e.target.value)}
                      onBlur={(e) => handleMemorialUpdate('cementerio', e.target.value)}
                    />
                    <span className="buscadoredit">
                      <ImSearch />
                    </span>

                  </div>
                </div>

              </div>


              <section className="contiene-input-imagen-perfil">
                <label htmlFor="file-input" className="input-subir">
                  <input id="file-input" name="imagen_perfil" type="file" onChange={handleFotoPerfilChange}
                  />
                  {imagenPreview && <img src={imagenPreview} alt="Vista previa de la imagen" />}
                  <div className="cambiar-foto" > <span className="cambiarfotoedit"><p>Cambiar</p><p>foto</p> </span></div>
                </label>
              </section>

              <section className="historiaeditcrear">
                <div className="titulohitoriacrear">
                  <PiScrollFill />
                  <h1>Su historia</h1>
                </div>
                <ReactQuill
                 className="inputedithistoria" 
                 placeholder="Escriba hechos importantes en la vida de su ser querido..."
                   value={historia}
                   onChange={handleChange}
                  onBlur={() => handleMemorialUpdate('historia', historia)} 
                  modules={{
                    toolbar: [
                      [],
                      [],
                      ['link'],
                     
                    ],
                  }}
                  formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'link', 'image', 'video'
                  ]}
                />
              </section>


              <div className="titulogaleriavideofinal">
                <div className="titulohitoriacrear">
                  <FaPhotoVideo />
                  <h1>Galería de recuerdos</h1>
                </div>
              </div>
                <section className="contienesub">
              <div className="titulofotocrear">
                <h1>Fotos</h1>
              </div></section>

              <section className="galeria-crear">
                <Grid container className="cardgaleriatodo">
                  {memorialData.galeria && memorialData.galeria.map((imagen, index) => (
                    <Grid key={index} item xs={4} className="contienecardgaleria">
                      <div className="cardfoto">
                        <div className="inputsubir">
                          {/* Muestra la imagen */}
                          <img src={`${backendURL}/${imagen.imagen}`} alt={`Imagen ${index + 1}`} className="imagen-seelcion-galeria" />
                          <div className="contieneicono">
                            <FaArrowUpFromBracket className="imgicono" />
                            <span>Subir Foto</span>
                          </div>
                        </div>
                        {/* Botones para editar o eliminar la imagen */}
                        <button className="button-cambiar-close" type="button" onClick={() => handleRemoveImage(index)}>
                          <IoClose />
                        </button>
                        <button type="button" className="editar-imagen" onClick={() => handleDescriptionButtonClick(index)}>
                          <p>Descripción</p> <img src={editar} />
                        </button>
                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={4} className="contienecardgaleria">
                    <div className="cardfoto">
                      <div className="inputsubir">

                        <input type="file" accept="image/*" className="cardinputgaleria" onChange={handleImageChange} />


                        <div className="contieneicono">
                          <FaArrowUpFromBracket className="imgicono" />
                          <span>Subir Foto</span>
                        </div>
                      </div>


                    </div>
                  </Grid>
                  {imagenesData.map((imagenData, index) => (
                    <Grid key={index} item xs={4} className="contienecardgaleria">
                      <div className="cardfoto">
                        <div className="inputsubir">

                          <input type="file" accept="image/*" className="cardinputgaleria" onChange={handleImageChange} />

                          {imagenData.imagen && (
                            <img src={URL.createObjectURL(imagenData.imagen)} alt="Smart Memorial" className='imagen-seelcion-galeria' />
                          )}
                          <div className="contieneicono">
                            <FaArrowUpFromBracket className="imgicono" />
                            <span>Subir Foto</span>
                          </div>
                        </div>
                        {imagenData.imagen && ( // Condición para mostrar los botones solo si hay una imagen seleccionada
                          <>
                            <button className="button-cambiar-close" type="button" onClick={() => handleRemoveImage(index)}>
                              <IoClose />
                            </button>
                            <button type="button" className="editar-imagen" onClick={() => handleDescriptionButtonClick(index)}>
                              <p>Descripción</p> <img src={editar} alt="Smart Memorial" />
                            </button>
                          </>
                        )}

                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={4} className="botonagregar">
                    <div className="cardnuevafoto">
                      <div className="inputnuevosubir">
                        <button type="button" className="cardnuevafoto" onClick={handleAddImage}> <div className="agregaricono">
                          <FiPlusCircle />
                        </div>
                        </button>
                      </div>
                    </div></Grid>



                </Grid>
              </section>

              {modalOpen && selectedImageIndex !== null && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={handleModalClose}><IoClose /></span>
                    <div className="titulo-galeriadescripcion"> < RiFileEditFill />
                      <h2>Agregar Descripción</h2>
                    </div>
                    <section className="aviso"> <span> si no desea agregar descripción </span> <span>haga click en continuar.</span></section>
                    <section className="formdescripciongaleriaimagen">
                      <div className="inputimagenespecifico">
                        <label className="tituloinputfecha">Título</label>
                        <input
                          type="text"
                          name="titulo"
                          className="inputdescripimag"
                          value={imagenesData[0]?.titulo || ''}
                          onChange={(e) => handleInputChange(0, e.target.name, e.target.value)}
                        />
                      </div>
                      <div className="inputimagenespecifico">
                        <div className="inputlabelinfofecha" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <label>Fecha de la foto</label>
                    
                         
                        </div>
                        <div className="inputfotocontainer">
                          <input
                            type="date"
                            className="inputefotofecha"
                            name='fecha'
                            value={imagenesData[0]?.fecha || ''}
                            onChange={(e) => handleInputChange(0, e.target.name, e.target.value)}
                          />
                         <div className="calendarioediticono ">
                            <RxCalendar className="iconoeditcalendario" />
                            </div>
                        </div>
                      </div>
                      <div className="inputimagenespecifico">
                        <label className="tituloinputfecha">Descripción</label>
                        <textarea
                          name="descripcion"
                          className="inputdescripitodo"
                          value={imagenesData[0]?.descripcion || ''}
                          onChange={(e) => handleInputChange(0, e.target.name, e.target.value)}
                        />
                      </div>
                    </section>
                    <section className="botonguardarimagen">
                      <button className="guardarimagen" type="button" onClick={handleEditImage}>{loading ?( <CircularProgress color="inherit"/>):("Continuar")}</button>
                    </section>
                  </div>
                </div>
              )}

              {showCreateOnlyImageConfirmation && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={handleModalClose}><IoClose /></span>

                    <div className="titulo-galeriadescripcion"> < RiFileEditFill />
                      <h2>Agregar Descripción</h2></div>
                      <section className="aviso"> <span> si no desea agregar descripción </span> <span>haga click en continuar.</span></section>
                    <section className="formdescripciongaleriaimagen">
                      <div className="inputimagenespecifico">
                        <label className="tituloinputfecha">Título</label>
                        <input
                          type="text"
                          name="titulo"
                          className="inputdescripimag"
                          value={imagenesData[selectedImageIndex]?.titulo || ''}
                          onChange={(e) => handleInputChange(selectedImageIndex, 'titulo', e.target.value)}
                        />
                      </div>

                      <div className="inputimagenespecifico">
                        <div className="inputlabelinfofecha" style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                          <label>Fecha de la foto</label></div>
                        <div className="inputfotocontainer">
                          <input
                            type="date"
                            className="inputefotofecha"
                            name='fechaNacimiento'
                            value={imagenesData[selectedImageIndex]?.fecha || ''}
                            onChange={(e) => handleInputChange(selectedImageIndex, 'fecha', e.target.value)}

                          />
                          <div className="calendarioediticono">
                            <RxCalendar className="iconoeditcalendario" />
                            </div>

                       
                        </div>
                      </div>

                      <div className="inputimagenespecifico">
                        <label className="tituloinputfecha">Descripción</label>
                        <textarea
                          name="descripcion"
                          className="inputdescripitodo"
                          value={imagenesData[selectedImageIndex]?.descripcion || ''}
                          onChange={(e) => handleInputChange(selectedImageIndex, 'descripcion', e.target.value)}
                        />
                      </div>

                      <section className="botonguardarimagen">
                        <div className="guardarimagen" onClick={handleCreateOnlyImageConfirmation} >{loading ?( <CircularProgress/>):("Continuar")}</div>
                      </section>
                    </section>
                  </div>
                </div>
              )}


              

<section className="videogaleria">
                <div className="titulovideoedit">
                  <h1>Videos</h1>
                </div>
                {showAlert && (
                  <div className={`alerta ${showAlert ? 'mostrar' : ''}`} onClick={() => setShowAlert(false)}>
                    <FiAlertCircle /> <p>El video no debe superar los 30 segundos</p>
                  </div>
                )}

                {showSuccessMessage && (
                  <div className={`successmessagecorrect ${showSuccessMessage ? 'show' : ''}`}>
                    <FaRegCircleCheck /><p>¡El video se ha subido correctamente!</p>
                  </div>
                )}
                 <Modal
        open={uploading}
        aria-labelledby="uploading-modal-title"
        aria-describedby="uploading-modal-description"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="background.paper"
            p={4}
            borderRadius={1}
            boxShadow={3}
          >
            <CircularProgress color="secondary" />
           
          </Box>
        </Box>
      </Modal>
                <section className="seccion-video-crear">
                  <div className="card-video" >
                  {video ? (
                 <div >  
          <ReactPlayer
            url={video}
            controls={true}
            width="100%"
            height="100%"
          /> 
          
          <div className="buttonvideoedit"> <button className="editvideogaleria" onClick={() => handleVideoClick()}>Editar video</button></div></div>
        ) : (
          <div className="no-video-message">
            <div className="inputsubir">
              <input
                id="filevideoinput"
                className="cardinputgaleria"
                name="file"
                type="file"
                accept="video/mp4"
                onChange={handleFileChange}
              />
              <div className="contieneicono">
                <FaArrowUpFromBracket className="imgicono" />
                <span>Editar Video</span>
              </div>
            </div>
          </div>
        )}
                   
                  </div>
                </section>

              </section>
              <section>
                <div className="tituloeditmensaje">
                  <IoIosMail />
                  <h1>Mensajes</h1>
                </div>
              </section>
              <section className="editcomentarios">
                {memorialData.comentarios && memorialData.comentarios.map((comentario, index) => (
                  <>
                    {comentario.visible === "1" && (
                      <div className="cardmensajeeditaparicion" key={index}>
                        <div className="basuraeditcomt">
                          <div onClick={() => {
                            setCommentToDelete(comentario.id);
                            setOpenModals(true);
                          }}>Eliminar <BsTrash3Fill /></div>
                        </div>
                        <div className="toocomt">
                          <div className="titulogeneradoeditcoment">
                            <h1>{capitalizeFirstLetter(comentario.titulo)}</h1>
                          </div>
                          <div className="comentarioeditgeneradocomt">
                            <p>{capitalizeFirstLetter(comentario.mensaje)}</p>
                          </div>
                          <div className="quienteditlorealizohora">
                            <p>{comentario.creador}</p>  •
                            <p>{new Date(comentario.fecha).toLocaleDateString('es-ES')}</p>
                          </div>
                          <div className="imagencomentario">
                            {comentario.imagen && (
                              <img
                                src={`${backendURL}/${comentario.imagen}`}
                                alt="smart memorial"
                                className="imagencomentariofinal"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </section>
              <Modal
                open={openModals}
                onClose={handleCloseModals}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', bgcolor: '#fff', boxShadow: 24, p: 4, borderRadius: "10px", '@media (max-width: 500px)': {
            width:'80%', p:2
          } }}>
                  <div className="tituloeliminarmensaje"><BsTrash3Fill />
                    <h2 id="modal-title">¿Desea eliminar este mensaje?</h2></div>
                  <div className="contienetodoslosbotonesediteliminar"> <button className="noeli" onClick={handleCloseModals}>No eliminar</button>
                    <button className="sieli" onClick={() => handleDeleteComment(commentToDelete)} >{loading ?( <CircularProgress color="inherit"/>):("Si eliminar")}</button></div>
                </Box>
              </Modal>

              <section className="campocomentariosingresarcliente">
                <div className="card-comentario">
                  <div className="agregarcomentario" onClick={handleOpenModal}>
                    <FiPlusCircle /> <p>Agregar un mensaje</p>
                  </div>
                </div>
              </section>

              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', height: 'auto', bgcolor: '#fff', boxShadow: 24, p: 2, borderRadius: "10px", display:"flex", flexDirection:"column",alignItems:"stretch", '@media (max-width: 500px)': {
            width:'80%', p:2
          } }}>
                  <section className="buttoncloseedit">
                  <button className="close-popup-button" onClick={handleCloseModal}>
                    <IoClose />
                  </button></section>
                  <section className="contienemesajecrearedit">
                  <div className="titulo-comdescripcion">
                    <IoIosMail />
                    <h2>Agregar un Mensaje</h2>
                  </div>
                  <div className="formulariocoment">
                    <form onSubmit={handleSubmit}>
                      <div className="formensajeedit">
                        <label>Ingrese su nombre</label>
                        <input type="text"
                          name="nombre"
                          className="deseditinput"
                          value={formData.nombre}
                          onChange={(e) => setFormData({ ...formData, nombre: e.target.value })}
                        />
                      </div>
                      <div className="formensajeedit">
                        <label>Título</label>
                        <input type="text"
                          name="titulo"
                          className="deseditinput"
                          value={formData.titulo}
                          onChange={(e) => setFormData({ ...formData, titulo: e.target.value })}
                        />
                      </div>
                      <div className="formensajeedit">
                        <label>Descripción</label>
                        <textarea name="descripcion"
                          className="deseditcomentario"
                          value={formData.descripcion}
                          onChange={(e) => setFormData({ ...formData, descripcion: e.target.value })}
                        />
                      </div>
                      <div className="formensajeedit">
                        <label>Agregar imágen (opcional)</label>
                        <div className="cardimagencom">
                          <div className="todocardimganecomt">
                            <input type="file" accept="image/*" className="desimagen"
                              onChange={(e) => setFormData({ ...formData, imagen: e.target.files[0] })} />
                            <div className="iconoagregarcomimagen">
                              <FaArrowUpFromBracket className="imgicono" />
                              <span>Subir Foto</span>
                            </div>
                          </div>
                          {formData.imagen && (
                            <img src={URL.createObjectURL(formData.imagen)} alt="smart memorial" />
                          )}
                        </div></div>
                      <button type="submit" className="botoneditguardarcomt" >{loading ?( <CircularProgress color="inherit"/>):("Guardar")}</button>
                    </form>
                  </div>
</section>
                </Box>
              </Modal>




              <section className="fondomemorial">
                <div className="titulofondomemorial">
                  <h1>Fondo</h1>
                </div>
              </section>
              <section className="selecionfondo">
                <Select
                  className="seleciondefondofinal"
                  value={selectedOption}
                  onChange={handleChangeFondo}
                  IconComponent={KeyboardArrowDownIcon}
                  sx={{
                    width: '100%',
                    border: '1px solid #585857',
                    borderRadius: '50px',
                    display: 'flex',
                    alignItems: 'flex-start',
                    color: '#585857',
                    fontFamily: 'Poppins',
                    '@media (max-width: 400px)': { fontSize: '0.7rem' }
                  }}
                >
                  <MenuItem value="atardecerVerano">
                    <div style={{ display: 'flex', alignItems:"center"  }}>
                      <ListItemText primary="Atardecer en verano" sx={{ width: '200px', fontFamily: "Poppins", fontSize:"0.7rem", '& .MuiTypography-root': { fontSize:"0.8rem", fontFamily: "Poppins"}, '@media (max-width: 310px)': { fontSize: '0.7rem' } }} />
                      <ListItemIcon>
                        <div style={{ backgroundColor: '#E28939', borderRadius: '50%', width: 20, height: 20, marginTop: '5px' }} />
                      </ListItemIcon>
                    </div>
                  </MenuItem>
                  <MenuItem value="naturalezaAustral">
                    <div style={{ display: 'flex', alignItems:"center"  }}>
                      <ListItemText primary="Naturaleza austral" sx={{ width: '200px', fontFamily: "Poppins",  fontSize:"0.7rem", '& .MuiTypography-root': { fontSize:"0.8rem", fontFamily: "Poppins"}, '@media (max-width: 310px)': { fontSize: '0.7rem' } }} />
                      <ListItemIcon>
                        <div style={{ backgroundColor: '#68A354', borderRadius: '50%', width: 20, height: 20, marginTop: '5px' }} />
                      </ListItemIcon>
                    </div>
                  </MenuItem>
                  <MenuItem value="clasicoSmartMemorial">
                    <div style={{ display: 'flex', alignItems:"center" }}>
                      <ListItemText primary="Clásico Smart Memorial" sx={{ width: '200px',  fontFamily: "Poppins", fontSize:"0.7rem", '& .MuiTypography-root': { fontSize:"0.8rem",  fontFamily: "Poppins"}, '@media (max-width: 310px)': { fontSize: '0.7rem' } }} />
                      <ListItemIcon>
                        <div style={{ backgroundColor: '#694B81', borderRadius: '50%', width: 20, height: 20, marginTop: '5px' }} />
                      </ListItemIcon>
                    </div>
                  </MenuItem>
                </Select>
              </section>
              <section className="eliminarmemorial" onClick={handleOpenModalseliminar}> <strong><h1> Eliminar memorial</h1></strong></section>
              <section className="guardarbotones">

                <button className="botonguardardemo" onClick={handleClick}>Previzualizar </button>

                <button className="botonguardarmensaje" onClick={handleClickfinal} >Guardar cambios </button>

              </section>

            </form>

          </section>

          <Modal
        open={openModalseliminar}
        onClose={handleCloseModalseliminar}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', bgcolor: '#fff', boxShadow: 24, p: 4, borderRadius: "10px", '@media (max-width: 500px)': {
            width:'80%', p:2
          } }}>
          <div className="tituloeliminarmensaje"><BsTrash3Fill />
            <h2 id="modal-title">¿Eliminar memorial?</h2>
          </div>
          <div className="informacioneliminarmemorial"> <span>Se eliminará toda la información
de este memorial</span></div>
          <div className="contienetodoslosbotonesediteliminar">
            <button className="noeli" onClick={handleCloseModalseliminar}>No eliminar</button>
            <button className="sieli"  onClick={handleEliminarMemorial}>{loading ?( <CircularProgress color="inherit"/>):("Si eliminar")}</button>
          </div>
        </Box>
      </Modal>
        </Box>
      </Container>

      <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
      <Modal open={enviandoAlertprogress} onClose={() => setenviandoAlertprogress(false)}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '350px',
      height: 'auto',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 1,
      borderRadius: '10px',
      '@media (max-width: 500px)': {
        width: '90%',
      },
    }}
  >
    <section style={{ textAlign: 'center', padding: '1rem' }}>
      <CircularProgressWithLabel value={progress} style={{ color: '#694B81' }} />
      <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
        Procesando solicitud...
      </p>
    </section>
  </Box>
</Modal>

    </section>
  )
}



