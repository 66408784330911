import React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { useState, useEffect, useRef } from 'react'
import { Container, Modal, Grid} from "@mui/material";
import "../../../../Style/usuario/demo.css";
import { PiScrollFill } from "react-icons/pi";
import { FaPhotoVideo } from "react-icons/fa";
import ReactPlayer from 'react-player';
import { IoIosMail } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import { IoList } from "react-icons/io5";
import GridViewIcon from '@mui/icons-material/GridView';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";



/* imagenes */
import fondo from "../../../../Image/generales/fondo-memorial.jpg"
import tombmorado from "../../../../Image/generales/icono-tumba-morado.png"
import tombverde from "../../../../Image/generales/icono-tumba-verde.png"
import tombnaranjo from "../../../../Image/generales/icono-tumba-naranjo.png"


export const Demomemorial = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ClientToken = location.state.user; // Obtener la información del usuario desde las props
  const grupoFamiliar = location.state.familia;
  const memorialId = location.state.memorial;
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState('list');
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const messagesSectionRef = useRef(null);
  const buttonRef = useRef(null);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [showMoreMessages, setShowMoreMessages] = useState(false);
  const [visibleComments, setVisibleComments] = useState([]);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showMoreCount, setShowMoreCount] = useState(3);  
  const [memorialData, setMemorialData] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

   

  const handleback = () => {
    const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorialId };
    navigate('/crearmemorial/mensajefondo', { state });
  };

  const handleClickfinal = () => {
    const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorialId };
    navigate('/qrmemoriales', { state });
  };

  const handleIconClick = (icon) => {
    setSelectedIcon(icon);
  };

  const handleOpenModals = (index) => {
      setSelectedImageIndex(index);
      setModalOpen(true);
    };
  
    const handleCloseModals = () => {
      setModalOpen(false);
      setSelectedImageIndex(null);
    };
  
    const handlePrevImage = () => {
      setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? memorialId.galeria.length - 1 : prevIndex - 1));
    };
  
    const handleNextImage = () => {
      setSelectedImageIndex((prevIndex) => (prevIndex === memorialId.galeria.length - 1 ? 0 : prevIndex + 1));
    };

    useEffect(() => {
      const handleScroll = () => {
        if (messagesSectionRef.current && buttonRef.current) {
          const messagesSectionTop = messagesSectionRef.current.getBoundingClientRect().top;
          const messagesSectionBottom = messagesSectionRef.current.getBoundingClientRect().bottom;
          const buttonHeight = buttonRef.current.offsetHeight;
  
          if (messagesSectionTop < window.innerHeight && messagesSectionBottom > buttonHeight) {
           setIsButtonVisible(true);
          } else {
           setIsButtonVisible(false);
          }
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    useEffect(() => {
      if (memorialId && memorialId.comentarios) {
        const visible = memorialId.comentarios.filter(comentario => comentario.visible === '1');
        setVisibleComments(visible.slice(0, showMoreCount));
        setShowMoreButton(visible.length > showMoreCount);
      }
    }, [memorialId, showMoreCount]);
  
    const handleShowMore = () => {
      const newCount = showMoreCount + 3;
      setVisibleComments(memorialId.comentarios.slice(0, newCount));
      setShowMoreCount(newCount);
      setShowMoreButton(visibleComments.length > newCount);
    };
    
    const getImageSrc = (fondo) => {
      switch (fondo) {
        case 'naturalezaAustral':
          return tombverde;
        case 'atardecerVerano':
          return tombnaranjo;
        default:
          return tombmorado;
      }
    };

  const imageSrc = getImageSrc(memorialId?.fondo);

  const getColorByFondo = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return '#E1EDDD';
      case 'atardecerVerano':
        return '#F9E7D7';
      default:
        return '#E1DBE6';
    }
  };

  const borderColor = getColorByFondo(memorialId.fondo);
  const backgroundColor = getColorByFondo(memorialId.fondo);

  const handleOpenModal = () => {
    setOpenModal(true);
};



  useEffect(() => {
    const fetchMemorialData = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/getMemorialId/${memorialId}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${ClientToken.token}`
          }
        });

        setMemorialData(response.data);
        setLoading(false);

      } catch (error) {
        console.error('Error al obtener los datos del memorial:', error);
      }
    };

    fetchMemorialData();

    // Cleanup
    return () => {
      // Aquí puedes realizar tareas de limpieza si es necesario
    };
  }, [memorialId]);

  if (loading) {
    return <div style={{
      position: 'fixed',
      zIndex: '1111111111111',
      width: "100%",
      height: "100%"

    }}> <Box sx={{ display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center', borderRadius:'10px', position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, -50%)',  maxWidth: '80%', textAlign: 'center'}}>
      <CircularProgress color="secondary"/>
      </Box></div>;
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
};

  function formatFecha(fecha) {
    // Verifica si la fecha es nula o indefinida
    if (!fecha) {
      return '';
    }
    
    // Convierte la fecha a un objeto Date
    const date = new Date(fecha);
    
    // Verifica si la fecha es válida
    if (isNaN(date.getTime())) {
      return '';
    }
    
    // Obtiene el día, el mes y el año
    const day = date.getDate();
    const month = date.toLocaleString('es-ES', { month: 'long' });
    const year = date.getFullYear();
    
    // Devuelve la fecha formateada
    return `${day} ${month} ${year}`;
  }

  const styles = {
    titleStyle: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return '#694B81';
        }
      })(),
    },
    fondomensajeStyle: {
      background: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return '#694B81';
        }
      })(),
    },
    fondosStyle: {
      background: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#F3F8F1';
          case 'atardecerVerano':
            return '#FDF5EF';
          default:
            return '#F0EDF3';
        }
      })(),
    },
    titulooscuroStyle: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#27632D';
          case 'atardecerVerano':
            return '#A04424';
          default:
            return '#D26999';
        }
      })(),
    },
    texto: {
      color: "#fff"
    }, selectedIconStyle: {
            
      cursor:"pointer",
      width: '40px',
      height: '40px',
      color: '#fff',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: (() => {
          switch (memorialId?.fondo) {
              case 'naturalezaAustral':
                  return '#68A354';
              case 'atardecerVerano':
                  return '#E28939';
              default:
                  return '#694B81';
          }
      })(),
    },
    iconStyle: {  
      cursor:"pointer",
      width: '40px',
      height: '40px', 
      borderRadius: '50%',   
      background: 'transparent',  // Aquí está el error corregido
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: (() => {
          switch (memorialId?.fondo) {
              case 'naturalezaAustral':
                  return '#68A354';
              case 'atardecerVerano':
                  return '#E28939';
              default:
                  return '#694B81';
          }
      })(),
    },
    modalContent: {
      maxHeight: '80vh', // Limitar la altura máxima
      overflowY: 'auto', // Hacer el contenido desplazable si es necesario
      position: 'relative',
    },
    modalImage: {
      width: '100%',
      maxHeight: '60vh',
      objectFit: 'contain',
    },
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (

      <Container component="main" maxWidth="xs" sx={{paddingLeft:"0px", paddingRight:"0px"}} >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#ffff",
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: (() => {
              switch (memorialData.fondo) {
                case 'naturalezaAustral':
                  return '#E1EDDD';
                case 'atardecerVerano':
                  return '#F9E7D7';
                default:
                  return '#E1DBE6';
              }
            })(),
          }}
        >
          <section className="contienehistoriademo">
            <div className="vistamemorial">
              <img src={fondo} alt="smart memorial" />
              <div className="titulodemomerorial">
                <h1 className="memorialtitulo" style={styles.titleStyle}>Memorial</h1>
                <h1 className="nombre" style={styles.titulooscuroStyle}>{memorialData.nombre}</h1>
                <h3 className="fechadefusion" style={styles.titleStyle}>{formatDate(memorialData.fecha_nacimiento)} • {formatDate(memorialData.fecha_defuncion)}</h3>
              </div>
              <div className="imagendemopersona">
                {memorialData && (

                  <img src={`${backendURL}/imagenes/memoriales/${memorialData.carpeta_perfil}/${memorialData.imagen_perfil}`} alt="smart memorial" />

                )}
              </div>
            </div>
            <div className="texto-historia" style={styles.fondosStyle}>
              <div className="titulohistoriademo" style={styles.titleStyle} ><PiScrollFill /> <h1>Su historia</h1>  </div>
              <p className="textohistoriademo"><DangerouslySetHTMLContent html={capitalizeFirstLetter(memorialData.historia)} /></p>
            </div>
          </section>


          <section className="demogaleriarecuerdo">
            {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
              <div className="titulogaleriabonito" style={styles.titleStyle}>  <FaPhotoVideo /> <h1>Galería de recuerdos</h1></div>
            )}
            <section className="contienensliderdemogaleria">
              {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
                <div className="titulodemofoto">
                  <div style={styles.titleStyle}><p>Fotos</p></div>
                  <div className="contienenvistafotos"> <p>Vista</p>
                    <div
                      className="iconosfotos"
                      style={selectedIcon === 'list' ? styles.selectedIconStyle : styles.iconStyle}
                      onClick={() => handleIconClick('list')}
                    >
                      <IoList className="list" />
                    </div>
                    <div
                      className="iconosfotos"
                      style={selectedIcon === 'grid' ? styles.selectedIconStyle : styles.iconStyle}
                      onClick={() => handleIconClick('grid')}
                    >
                      <GridViewIcon />
                    </div>
                  </div>
                </div>
              )}
              <div className="contiendedemogaleria">
                {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
                  selectedIcon === 'list' ? (
                    memorialData.galeria.map((imagen, index) => (
                      <div style={styles.fondosStyle} key={index}>
                        <div className="marcodefotodemo">
                          <div className="caratulademogaleria">
                            <img src={`${backendURL}/${imagen.imagen}`} alt={imagen.titulo} />
                          </div>
                          <div className="textocarddemogaleria">
                            <h3 style={styles.titulooscuroStyle}>{formatFecha(imagen.fecha)}</h3>
                            <h1 style={styles.titleStyle}>{capitalizeFirstLetter(imagen.titulo)}</h1>
                            <h2>{capitalizeFirstLetter(imagen.descripcion)}</h2>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {memorialData.galeria.map((imagen, index) => (
                        <Grid item xs={4} key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "5px -5px" }}>
                          <div className="contienenmarcofotosdos" onClick={() => handleOpenModals(index)} >
                            <div className="marcodefotosdosdemo">
                              <div className="caratulademodosgaleria">
                                <img src={`${backendURL}/${imagen.imagen}`} alt={imagen.titulo} />
                              </div>

                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  )
                )}
              </div>
            </section>
          </section>


          <section className="contienenvideosdemo">
            {memorialData && memorialData.video && (
              <div className="videotitulodemo" style={styles.titleStyle}> Videos</div>
            )}
            {memorialData && memorialData.video && (
              <ReactPlayer
              url={`${backendURL}/video/${memorialData.video}?t=${new Date().getTime()}`}
              controls={true}
              width='100%'
              height='100%'
          />
            )}
          </section>
<div className="contienentodoslosmensajedemodos">
          <section className="demorecuerdomensaje">
            {memorialData && memorialData.comentarios && memorialData.comentarios.length > 0 && (
              <div className="titulodemomensaje" style={styles.titleStyle}> <IoIosMail /> <h1>Mensajes</h1></div>
            )}
              
            {memorialData && memorialData.comentarios && memorialData.comentarios.map((comentario, index) => (
               <>
                <div key={index} className="contienencarddemomensaje" style={styles.fondomensajeStyle}>
                <div className="contienentextomensajedemo">
                  <h1 style={styles.titulooscuroStyle}>{capitalizeFirstLetter(comentario.titulo)}</h1>
                  <h2 style={styles.texto} >{capitalizeFirstLetter(comentario.mensaje)}</h2></div>
                <div className="quienloescriviodemo" style={styles.texto}><h3>{comentario.creador}</h3>-<p> {formatDate(comentario.fecha)}</p></div>
                <div className="imagendemmensajedemo">
                  {comentario.imagen && (
                    <div className="imagendemmensajedemo">
                      <img src={`${backendURL}/${comentario.imagen}`} alt="smart memorial" />
                    </div>
                  )}
                </div>
               
              </div>
              <section className="corazonmemorialdenmo">
                                <div className="containerheart">
                                    <div className="heart">
                                        <svg width="50" height="50" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z"/>
                                        </svg>
                                    </div>
                                    <div className="heart">
                                        <svg width="40" height="40" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z"/>
                                        </svg>
                                    </div>
                                    <div className="heart">
                                        <svg width="35" height="35" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z"/>
                                        </svg>
                                    </div>
                                </div>
                            </section>
            </>
            ))}
          </section></div>

          
<section className="contienencementerio">
    
    {memorialData.cementerio && (
            <section className="titulocementerio" style={styles.titleStyle}>
                <img src={imageSrc} className="iconocementerio" alt="smart memorial" title="smart memorial" />
                <div className="titulocementerio"> <h1>Cementerio donde nuestro</h1> <h1>ser querido descansa</h1></div> 
            </section>
        )}
        <section className="contieneninformacioncementerio">
            <p>{capitalizeFirstLetter(memorialData.cementerio)}</p>
    
        </section>
    
    </section>
          <section className="sesiondebotonesdemomemorial">
            <button className="botoneditardemo" onClick={handleback} >Seguir editando</button>
            <button className="botoncrearmemorialfinal" onClick={handleClickfinal} >Crear memorial</button></section> </Box>



            {modalOpen && selectedImageIndex !== null && (
        <Modal open={modalOpen} onClose={handleCloseModals}>
          <Box
            style={styles.fondosStyle}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '400px',
              height: 'auto',
              boxShadow: 24,

              borderRadius: '10px',
              '@media (max-width: 500px)': {
                width: '90%',
              },
            }}>
            <div style={styles.modalContent}>
              <div className="contienenclosefotosmemorial">
                <IconButton style={styles.titleStyle} onClick={handleCloseModals}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="marcodefotodemo">
                <div className="caratulademogaleria">
                  <img
                    src={`${backendURL}/${memorialData.galeria[selectedImageIndex].imagen}`}
                    alt={memorialData.galeria[selectedImageIndex].titulo}
                    style={styles.modalImage}
                  />
                </div>
                <div className="textocarddemogaleria">
                  <h3 style={styles.titulooscuroStyle}>{formatFecha(memorialData.galeria[selectedImageIndex].fecha)}</h3>
                  <h1 style={styles.titleStyle}>{capitalizeFirstLetter(memorialData.galeria[selectedImageIndex].titulo)}</h1>
                  <h2>{capitalizeFirstLetter(memorialData.galeria[selectedImageIndex].descripcion)}</h2>
                </div>
              </div>
            </div>
            <div className="contienenflechasfotos">
              <IconButton onClick={handlePrevImage}>
                <IoIosArrowBack style={styles.titleStyle} /> <p style={styles.titleStyle} className="tectoiconoflechas">Anterior</p>
              </IconButton>
              <IconButton onClick={handleNextImage}>
                <p style={styles.titleStyle} className="tectoiconoflechas">Siguiente</p> <IoIosArrowForward style={styles.titleStyle} />
              </IconButton>
            </div>
          </Box>
        </Modal>
      )}


      </Container>

  );
}