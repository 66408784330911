import React, { useState, useEffect } from 'react';
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { Backdrop, Fade} from '@material-ui/core';
import { Modal } from '@mui/material';
import { Terminosycondiciones } from './terminosycondiciones';
import { IoClose } from "react-icons/io5";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import "../../../Style/Registro/registro-form.css";
import { RxCalendar } from "react-icons/rx";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { FiAlertCircle } from "react-icons/fi";
import { Validacion } from './validacion';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { IoIosArrowBack } from "react-icons/io";
import { styled } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

/* comoponentes generales */
import { Louding } from '../../generales/louding';




const StyledSelect = styled(Select)(({ theme, error }) => ({
  padding: '1px 13px',
  color:'#694B81',
  position:'relative',
  left:'0.5%',
  fontFamily: "Poppins",
  width:'90%',
  height:'55px',
  fontSize:'0.8rem',
  borderRadius: '50px',
  border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F0EDF3', // Color del borde por defecto
    },
    '&:hover fieldset': {
      borderColor: '#585857', // Color del borde al pasar el mouse
    },
    '&.Mui-focused fieldset': {
      borderColor: '#585857', // Color del borde en foco
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#585857', // Color del borde en foco
    borderWidth: '2px', // Ancho del borde en foco
  },
  '@media (max-width: 310px)': {
    fontSize:'0.7rem',
    width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
    maxWidth: '96%',
  },
  
}));

const StyledAutocomplete  = styled(Autocomplete )(({ theme, error }) => ({
  
  fontFamily: "Poppins",
  width:'90%',
  height:'55px',
  position:'relative',
  left:'0.5%',
  fontSize:'0.8rem',
  borderRadius: '50px',
  border: `3px solid ${error ? '#D26999' : '#F0EDF3'}`, // Cambia el color del borde si hay un error
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F0EDF3', // Color del borde por defecto
    },
    '&:hover fieldset': {
      borderColor: '#585857', // Color del borde al pasar el mouse
    },
    '&.Mui-focused fieldset': {
      borderColor: '#585857', // Color del borde en foco
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#585857', // Color del borde en foco
    borderWidth: '2px', // Ancho del borde en foco
  },
  
  '& .MuiOutlinedInput-root': {
    borderRadius: '50px', // Establece el radio de borde
    border: '0px', // Establece el borde a 0
    height: '52px', // Establece la altura
    fontSize: '0.8rem', // Establece el tamaño de fuente
    textTransform: 'capitalize', // Transforma el texto a mayúsculas
  },
  '@media (max-width: 310px)': {
    fontSize:'0.7rem',
    width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
    maxWidth: '96%',
  },
 
  
}));




export const Registroform = () => {
  const location = useLocation();
  const { suscripcion } = location.state;
  const [selectedPack, setSelectedPack] = useState('');
  const [loading, setLoading] = useState(false); // Estado para controlar la carga
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [termsError, setTermsError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [registrationError, setRegistrationError] = useState('');
  const [funeraria, setFuneraria] = React.useState(null);
  const [funerarias, setFunerarias] = useState([]);
  const [age, setAge] = React.useState('');
  const [ages, setAges] = React.useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordcorrect, setShowPasswordcorrect] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
 
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [formData, setFormData] = useState({
    name: '',
    apellido: '',
    fecha_nacimiento: '',
    genero: '',
    email: '',
    telefono: '+56 ',
    funeraria: '',
    password: '',
    codigo: suscripcion.codigo,
  });
 
  const navigate = useNavigate();

   useEffect(() => {
      if (suscripcion) {
        setSelectedPack(suscripcion.id_suscripcion);
      }
    }, [suscripcion]);


  const handleChange = (event) => {
    const { name, value } = event.target;
  
   
  
    setFormData({
      ...formData,
      [name]: value
    });
  
    // Limpiar el error asociado con el campo actual
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: '' // Limpiar el error del campo actual
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataFromEvent = new FormData(event.target);
    
    
    const formDataObject = {};

    

    formDataFromEvent.forEach((value, key) => {
      formDataObject[key] = value;
    });

    const formattedDate = dayjs(formDataObject.fecha_nacimiento, 'DD-MM-YYYY').format('YYYY-MM-DD');
    formDataObject.fecha_nacimiento = formattedDate;

    let formErrors = {};
    for (let [key, value] of formDataFromEvent.entries()) {
      if (value.trim() === '' || value.trim() === '+56') {
        formErrors[key] = 'Campo no completado';
      }
    }

    setErrors(formErrors);

    if (!termsAccepted) {
      setTermsError('Debes aceptar los términos y condiciones para registrarte.');
      return;
    }

    try {
      setOpenModal2(true);
      setLoading(true); // Muestra el modal de carga al hacer clic en el botón de registro

      const response = await fetch(`${backendURL}/api/registerUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();
      
      if (!response.ok || response.status !== 201) {
        setOpenModal2(false); // Muestra el segundo modal si hay un error en la solicitud
        throw new Error(data.error || 'Error al registrar usuario');
      }

      const allFieldsCompleted = Object.values(formErrors).every(error => !error);
      const emailExistsError = data.error === 'El correo electrónico ya está registrado';

      if (allFieldsCompleted && !emailExistsError) {
        setOpenModal2(false); // Muestra el segundo modal cuando la solicitud es exitosa
        handleNextPage(); // Muestra la siguiente página
      } else if (emailExistsError) {
        setFormData(prevState => ({
          ...prevState,
          emailExistsError: true
        }));
        setRegistrationError('El correo electrónico ya está registrado');
      }
    } catch (error) {
      console.error(error);
      setRegistrationError('El correo electrónico ya está registrado');
    } finally {
      setLoading(false);
      setOpenModal2(false);
    }
  };

  useEffect(() => {
    const fetchFunerarias = async () => {
      try {
        const response = await fetch(`${backendURL}/api/funerariasGetRegister`);
        if (!response.ok) {
          throw new Error('Error al obtener las funerarias');
        }
        const data = await response.json();

        const funerariasFiltradas = data.filter(funeraria => funeraria.grupo_familiar.some(grupo => !grupo.id_usuario));

        setFunerarias(funerariasFiltradas);
      } catch (error) {
        console.error(error);
      }
    };

    fetchFunerarias();
  }, []);

  

  

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };


  const handleChangeGenero = (event) => {
    setAge(event.target.value);
    handleChange(event); // Actualizar el estado formData con el nuevo valor de 'genero'

    setFormData({
      ...formData,
      genero: ''
    });
    setErrors(prevErrors => ({
      ...prevErrors,
      genero: false // Limpiar el error de género
    }));
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordcorrectVisibility = () => {
    setShowPasswordcorrect(!showPasswordcorrect);
  };



  const handleChangeInput = (event) => {
    const { name } = event.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
      // Limpiar el error correspondiente
    }));
  };

  const handleChangeFecha = (event) => {
    const { value } = event.target;
    // Convertir el valor de la fecha a un objeto Date
    const date = new Date(value);
  
    if (!isNaN(date)) {
      // Limpiar el error asociado con la fecha cuando se selecciona una nueva fecha
      setErrors(prevErrors => ({
        ...prevErrors,
        fecha_nacimiento: '' // Limpiar el error de fecha
      }));
  
      // Formatear la fecha para que no incluya la hora
      const fechaFormateada = date.toISOString().split('T')[0];
  
      // Actualizar el estado del formulario con la nueva fecha seleccionada
      setFormData({
        ...formData,
        fecha_nacimiento: fechaFormateada // Actualizar con la fecha formateada
      });
  
      // Llamar a handleChange con la nueva fecha
      handleChange({ target: { name: 'fecha_nacimiento', value: fechaFormateada } });
    } else {
      console.error("Invalid date value");
    }
  };


  const handleChangefuneraria = (event, value) => {
  
  
    setFuneraria(value); // Actualizar el estado de la funeraria con el objeto seleccionado
  
    // Limpiar el error asociado con la funeraria cuando se selecciona una opción
    setErrors(prevErrors => ({
      ...prevErrors,
      funeraria: false // Limpiar el error de funeraria
    }));
  
    // Si value es null, significa que se eliminó la selección
    // En ese caso, establece el valor del ID de la funeraria en el estado del formulario como una cadena vacía
    setFormData(prevFormData => ({
      ...prevFormData,
      funeraria: value ? value.id : '' // Almacena el ID de la funeraria seleccionada, o un valor vacío si no se selecciona nada
    }));
  };

  const handleChangeContraseña = (event) => {
    setPassword(event.target.value);
    if (password.length < 6) {
      setPasswordError('La contraseña debe tener al menos 6 caracteres');
    } else {
      setPasswordError('');
    }
  };


  // Validar que la contraseña sea igual a la contraseña repetida
  const handleChangeRepetirContraseña = (event) => {
    const confirmPassword = event.target.value;
  
    // Validar la longitud mínima de caracteres
    if (confirmPassword.length < 6) {
      setPasswordError('La contraseña debe tener al menos 6 caracteres');
    } else {
      setPasswordError('');
    }
  
    // Validar si la contraseña repetida coincide con la contraseña principal
    if (confirmPassword !== password) {
      setPasswordError('Las contraseñas no coinciden');
    } else {
      setPasswordError(''); // Limpiar el mensaje de error si las contraseñas coinciden
    }
  };
  
  

  /* modal terminos y condiciones */
  const handleOpenModal = () => {
    setOpenModal(true);
    setTermsAccepted(true); 
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    
    <>
    <Modal
        open={openModal2}
        onClose={() => setOpenModal2(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal2}>
          <div
            style={{
              
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
           <Louding/>
           </div>
        </Fade>
      </Modal>
       
  
      <Container  maxWidth="sm" sx={{paddingLeft:'0px', paddingRight:'0px'}}>

        <Box
          sx={{
           
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: 'center',
            padding: '0px 10px'
          }}
        >
          <section className={`pagina-${currentPage}`}>
            {currentPage === 1 && (
              <>
                <section className='retroceso-1'>
                <Link to="/verificacion-de-registro">
                <IoIosArrowBack />
                </Link>
                </section>
                <div className="contiene-titulo-registro-form">
                  <h1 className="titulo-registro-form">
                   Es muy simple
                  </h1>
                  <h1 className="tituloregistroform">Ingresa tus datos</h1>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="input-registor-form">
                    <label htmlFor='name'>Nombres</label>
                   <div className='contieneinputregister'> <input onChange={(e) => {
                      handleChange(e);
                      handleChangeInput(e);
                    }} className={`input-registro ${errors.name && 'input-error'}`} name="name" id="name"
                      value={formData['name']} />
                  </div>  {errors.name && <span className="error-message"> <FiAlertCircle className='icon-error' />{errors.name}</span>}
                  </div>
                  <div className="input-registor-form">
                    <label htmlFor='apellido'>Apellidos</label>
                    <div className='contieneinputregister'> <input onChange={handleChange} className={`input-registro ${errors.apellido && 'input-error'}`} name="apellido" id="apellido" value={formData['apellido']} />
                   </div> {errors.apellido && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.apellido}</span>}
                  </div>
                  <div className="input-registor-form">
  <label htmlFor="fecha_nacimiento">Fecha de Nacimiento</label>
  <div className='contieneinputregister'>
    <div className="inputregistrocontainer">
      <input
        type="date"
        className={`inputregistrofecha ${errors.fecha_nacimiento && 'input-error'}`}
        name='fecha_nacimiento'
        id='fecha_nacimiento'
        onChange={handleChangeFecha} 
        error={errors.fecha_nacimiento}   
        value={formData.fecha_nacimiento} // Asegúrate de que el valor sea el correcto del estado
      />
      <div className="calendariocreacionicono">
        <RxCalendar className="iconocrearmemorialcalendario" />
        </div>
      
    </div>
  </div>
  {errors.fecha_nacimiento && <span className="error-message"> <FiAlertCircle className='icon-error' />{errors.fecha_nacimiento}</span>}
</div>
                  
                  <div className="input-registor-form">
                    <label htmlFor='genero'>Género</label>
                    <div className='contieneinputregister'> < StyledSelect
                      name='genero'
                      id='genero'
                      sx={{textAlign:"left"}}
                      className={`input-select-registro ${errors.genero && 'input-error'}`}
                      value={formData.genero}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      IconComponent={KeyboardArrowDownIcon}
                      error={errors.genero}
                    >
                      <MenuItem value="">
                        <em>Seleccionar género...</em>
                      </MenuItem>
                      <MenuItem value={"Masculino"}>Masculino</MenuItem>
                      <MenuItem value={"Femenino"}>Femenino</MenuItem>
                      <MenuItem value={"Me identifico con otro género"}>Me identifico con otro género </MenuItem>
                    </ StyledSelect>
                  </div>  {errors.genero && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.genero}</span>}
                  </div>
                  <div className="input-registor-form">
          <label htmlFor='email'>Correo electrónico</label>
          <div className='contieneinputregister'> <input
            onChange={(e) => {
              handleChange(e);
              setFormData(prevState => ({
                ...prevState,
                emailExistsError: false // Limpiar el error cuando el usuario modifica el campo de correo electrónico
              }));
            }}
            className={`input-registro ${errors.email || registrationError ? 'input-error' : ''}`} // Aplicar la clase input-error si hay error en el email o el email ya existe
            name="email"
            id="email"
            value={formData['email']}
          /></div> 
          {errors.email && (
            <span className="error-message">
              <FiAlertCircle className='icon-error' />
              {errors.email}
            </span>
          )}
          {registrationError && (
            <span className="error-message">
              <FiAlertCircle className='icon-error' />
              {registrationError}
            </span>
          )}
        </div>
                  <div className="input-registor-form">
                    <label htmlFor='telefono'>Número de celular</label>
                    
                    <div className='contieneinputregister'>
                      <input
                        value={formData['telefono']}
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeInput(e);
                        }}
                        className={`input-registro ${errors.telefono && 'input-error'}`}
                        name="telefono"
                        id="telefono"
                      /></div>
                   
                    {errors.telefono && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.telefono}</span>}
                 </div>
                  <div className="input-registor-form">
                    <label htmlFor='pack'>Pack Memorial contratado</label>
                    <div className='contieneinputregister'>
                    <StyledSelect
            name='pack'
            id='pack'
            value={selectedPack}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            IconComponent={KeyboardArrowDownIcon}
            disabled
            className="input-select-registro"
            sx={{ textAlign: "left" }}
          >
            <MenuItem value="">
              <em>Selecciona el tipo Pack Memorial...</em>
            </MenuItem>
            <MenuItem value={1}> Pack Smart Memorial Full</MenuItem>
            <MenuItem value={2}> Pack SmartMemorial Básico</MenuItem>
          </StyledSelect>
                       </div> 
                   
                  </div>
                  <div className="input-registor-form">
                    <label htmlFor='funeraria'>Elige el servicio Smart Memorial</label>
                    <div className='contieneinputregister'> <StyledAutocomplete
                    sx={{textTransform:"capitalize", height:'52px', fontSize:'0.8rem'}}
                    id='funeraria'
                    name='funeraria'
                    className={`input-select-registro ${errors.funeraria && 'input-error'}`}
  value={funeraria}
  onChange={(event, newValue) => {
    setFuneraria(newValue); // Actualiza el valor de la funeraria seleccionada
    handleChangefuneraria(event, newValue); // Llama a la función handleChangefuneraria con el evento y el valor seleccionado
  }}
  options={funerarias.sort((a, b) => (a.nombre === 'smart memorial' ? -1 : 0))}
  getOptionLabel={(option) => option.nombre}
  renderInput={(params) => <TextField {...params} placeholder="selecciona el servicio de como obtuviste smart" />}
/></div>
                    {errors.funeraria && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.ffuneraria}</span>}
                  </div>
                  <div className="input-registor-form">
                    <label>Contraseña</label>
                    <div className='input-contraseña'>
                     <input
                        onChange={(e) => { handleChange(e); handleChangeInput(e); handleChangeContraseña(e) }}
                        type={showPassword ? 'text' : 'password'}
                        className={`input-registro ${errors.password && 'input-error'} ${passwordError && 'input-error'}`}
                        name="password"
                        value={formData['password']}
                        placeholder="Contraseña"
                      />
                      <span className="togglepassword" onClick={togglePasswordVisibility}>
                        {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                      </span></div>
                    {errors.password && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.password}</span>}
                    {passwordError && <span className="error-message"><FiAlertCircle className='icon-error' />{passwordError}</span>}
                  </div>

                  <div className="input-registor-form">
                    <label>Repetir contraseña</label>
                    <div className='input-contraseña'>
                      <input
                        onChange={(e) => { handleChangeInput(e); handleChangeRepetirContraseña(e); }}
                        type={showPasswordcorrect ? 'text' : 'password'}
                        className={`input-registro ${errors.passwords && 'input-error'} ${passwordError && 'input-error'}`}
                        name="passwords"
                        placeholder="Contraseña"
                      />
                      <span className="togglepassword" onClick={togglePasswordcorrectVisibility}>
                        {showPasswordcorrect ? <FaRegEyeSlash /> : <FaRegEye />}
                      </span>
                    </div>
                    {errors.password && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.password}</span>}
                    {passwordError && <span className="error-message"><FiAlertCircle className='icon-error' />{passwordError}</span>}
                  </div>
                  <div className="unoterminos">
          <label className="container">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={(e) => {
                setTermsAccepted(e.target.checked);
                setTermsError(''); // Limpiar el error cuando se marca/desmarca el checkbox
              }}
            />
            <svg viewBox="0 0 64 64" height="2em" width="2em">
              <path
                d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                pathLength="575.0541381835938"
                className="path"
              ></path>
            </svg>
          </label>
          <span>
            Acepto <strong  onClick={handleOpenModal} >términos y condiciones</strong>
          </span>
          
        </div>{termsError && <span className="error-message"> <FiAlertCircle className='icon-error' />{termsError}</span>}

                 <Modal open={openModal} onClose={handleCloseModal}>
                  <section className='contiene-modal-terminos'>
        <div>
          <button className='boton-close-termino' onClick={handleCloseModal}><IoClose /></button>
        </div>
        <section>
          <Terminosycondiciones   onCloseModal={handleCloseModal} /></section></section>
      </Modal>   
                  
                  <div className="boton-registro">

                    <button type="submit"> Regístrarse</button>

                  </div>

                </form>
              </>
            )}
            {currentPage === 2 && (
              <>
                <Validacion handlePrevPage={handlePrevPage} email={formData.email} />
              </>

            )}
          </section>
        </Box>
      </Container>
  
    
    </>
  ); 
};
