import React from "react";
import {Grid, Box , CircularProgress, Modal, Typography, Container} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../Style/usuario/evento.css";
import { RxCalendar } from "react-icons/rx";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from "axios";
import { styled } from '@mui/system';
import Select from '@mui/material/Select';
import { FiAlertCircle } from "react-icons/fi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { format } from "date-fns";
import { MdOutlineAutorenew } from "react-icons/md";

export const Suscripcionusuario = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const location = useLocation();
    const ClientToken = location.state.user;
    const [suscripciones, setSuscripciones] = useState([]);

    useEffect(() => {
        const fetchSuscripciones = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/getsuscripcionusuario/${ClientToken.userId}`, {
                    headers: {
                        Authorization: `Bearer ${ClientToken.token}`
                    }
                });
                setSuscripciones(response.data);
            } catch (error) {
                console.error('Error al obtener los datos de las suscripciones:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSuscripciones();
    }, [ClientToken, backendURL]);

   

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress color="secondary" />
            </Box>
        );
    }

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy');
    };

    const calculateDaysRemaining = (startDate, endDate) => {
        if (!startDate || !endDate) return 'N/A';
        const start = new Date(startDate);
        const end = new Date(endDate);
        const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
        const diffInTime = end.getTime() - start.getTime();
        const diffInDays = Math.ceil(diffInTime / oneDay);
        return diffInDays;
    };
    return(
        <Box sx={{ display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", width:"100%"}}>
            
           
            {suscripciones.length === 0 ? (
                 <CircularProgress color="secondary"/>
            ) : (
               <section className="contienensuscripc">
                    {suscripciones.map(suscripcion => (
                        <div key={suscripcion.id} style={{width:"100%"}}>
                            {suscripcion.estado === 'ACTIVO' ? (
                               <section className="doscontiene"> <div className="activo">
                                <FaRegCircleCheck />  <div className="contienetituloactivo"><p>Su suscripción se </p> <p>encuentra al día</p></div>
                          </div>
                          
                          
                          <section className="pack">
                          {suscripcion.id_suscripcion == 1 ? (
                           <> <div className="cardpack">
                                <h1>Memorial Full</h1>
                                <h3>Placa para lápida + servicio digital
</h3>
                            </div>
                            <div className="suscripcionvence"> <p>Vence el {formatDate(suscripcion.fecha_termino)} </p> <span> (restan {calculateDaysRemaining(suscripcion.fecha_inicio, suscripcion.fecha_termino)} días)</span></div>
                            </>
                            ) : (
                         <>
                                <div className="cardpack">
                                <h1>Memorial Básico</h1>
                                <h3> servicio digital</h3>
                            </div>
                            <div className="suscripcionvence"> <p>Vence el {formatDate(suscripcion.fecha_termino)} </p> <span> (restan {calculateDaysRemaining(suscripcion.fecha_inicio, suscripcion.fecha_termino)} días)</span></div> </>
                            
                             
                            )}
                         </section>
                          
                          </section>  ) : (
                               <>
                               <section className="inactivo">
                               <h1>Su suscripción se </h1>
                               <h1><strong>encuentra vencida</strong></h1> 
                               <span className="inforinactivo">El grupo memorial se mantendrá inhabilitado</span>
           </section>
                              
                      <section className="pack">
                          {suscripcion.id_suscripcion === 1 ? (
                           <> <div className="cardpack">
                                <h1>Memorial Full</h1>
                                <h3>Placa para lápida + servicio digital
</h3>
                            </div>
                            <div className="suscripcionvence"> <p>Vence el {formatDate(suscripcion.fecha_termino)} </p> <span> (restan {calculateDaysRemaining(suscripcion.fecha_inicio, suscripcion.fecha_termino)} días)</span></div>
                           
                           <section className="renovacion">
                            <button className="botonrenovar"> <MdOutlineAutorenew /> Renovar suscripcion </button>
                           </section>
                            </>
                            ) : (
                         <>
                                <div className="cardpack">
                                <h1>Memorial Básico</h1>
                                <h3> servicio digital</h3>
                            </div>
                            <div className="suscripcionvence"> <p>Vence el {formatDate(suscripcion.fecha_termino)} </p> <span> (restan {calculateDaysRemaining(suscripcion.fecha_inicio, suscripcion.fecha_termino)} días)</span></div> 
                            
                            <section className="renovacion">
                            <button className="botonrenovar"> <MdOutlineAutorenew /> Renovar suscripcion </button>
                           </section>
                            
                            </>
                            
                             
                            )}
                         </section>
                         </> 
                            )}
                        </div>
                    ))}
               </section>
            )}
            </Box>

    )
}