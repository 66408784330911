import React, { useState,useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../context/usecontext";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import useMediaQuery from '@mui/material/useMediaQuery';
import "../../../Style/admi/admiperfil.css"


const StyledForm = styled('form')({
  '& .MuiTextField-root': {
    marginBottom: '1rem',
  },
});

const FunerariaStore = ({togglePopup, fetchData}) => {
   
    const { adminToken } = useAuth();
    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    const [direccion, setDireccion] = useState("");
    const [mensajeAnual, setMensajeAnual] = useState("");
    const [web, setWeb] = useState("");
    const [logo, setLogo] = useState(null);
     const [openAlert, setOpenAlert] = useState(false);
     const backendURL = process.env.REACT_APP_BACKEND_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("nombre", nombre);
        formData.append("email", email);
        formData.append("direccion", direccion);
        formData.append("mensaje_anual", mensajeAnual);
        formData.append("web", web);
        formData.append("logo", logo);

        try {
            const response = await axios.post(`${backendURL}/api/funerariasStore`, formData, {
                headers: {
                    'Authorization': `Bearer ${adminToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201) {
                handleOpenAlert();
                if (fetchData) {
                    fetchData();
                }
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
        }
    };

    
    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    useEffect(() => {
        if (openAlert) {
            // Establecer un temporizador para cerrar el modal después de dos segundos
            const timer = setTimeout(() => {
                handleCloseAlert();
            }, 2000); // 2000 milisegundos = 2 segundos

            // Limpiar el temporizador al desmontar el componente o al actualizar openAlert
            return () => clearTimeout(timer);
        }
    }, [openAlert]);

    const handleCloseAlert = () => {
        setOpenAlert(false);
        togglePopup();
        // Restablecer los valores del formulario
        setNombre("");
        setEmail("");
        setDireccion("");
        setMensajeAnual("");
        setWeb("");
        setLogo(null);
    };

    const isSmallScreen = useMediaQuery('(max-width: 400px)');
    // Establecer el valor de maxWidth en función del tamaño de la pantalla
    const maxWidth = isSmallScreen ? 200 : 400;

  

    return (
        <section>
            <section className="titulocreacionservicio">
                <h1>Creación de nuevo servicio</h1>
                <h1> Smart Memorial</h1>
            </section>
        <form onSubmit={handleSubmit}>
            <div className="inputcrearservicio">
            <TextField
                label="Nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                fullWidth
                required
            /></div>
            <div className="inputcrearservicio">
            <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
            /></div>
            <div className="inputcrearservicio">
            <TextField
                label="Dirección"
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
                fullWidth
                required
            /></div>
            <div className="inputcrearservicio">
            <TextField
                label="Mensaje Anual"
                value={mensajeAnual}
                onChange={(e) => setMensajeAnual(e.target.value)}
                multiline
                fullWidth
            /></div>
            <div className="inputcrearservicio">
            <TextField
                label="Sitio Web"
                value={web}
                onChange={(e) => setWeb(e.target.value)}
                fullWidth
            /></div>
            <Box mb={2}>
                <input
                    type="file"
                    accept="image/png"
                    onChange={(e) => setLogo(e.target.files[0])}
                />
            </Box>
            <Modal
                    open={openAlert}
                    onClose={handleCloseAlert}
                    aria-labelledby="alert-modal-title"
                    aria-describedby="alert-modal-description"
                    sx={{ zIndex: 9999999999999999999}}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            width: '100%',
                            maxWidth: maxWidth,
                            bgcolor: "transparent",
                            border: "0px",
                            p: 4,
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 9999999999999999999
                        }}
                    >
                        <Alert severity="success" sx={{ width: '100%',  zIndex: 9999999999999999999 }}>
                          Servicio Smart Memorial creado correctamente.
                        </Alert>
                    </Box>
                </Modal>
            <button className="buttonenviarserviciocreado" type="submit">Enviar</button>
        </form>
        </section>
    );
};

export default FunerariaStore;
