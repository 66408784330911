import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./Components/context/usecontext";
import { UsersProvider } from "./Components/context/userscontexts";


/* Generales */
import { Navbar } from "./Components/nav-fotter/navbar";
import { Navbarcliente } from "./Components/nav-fotter/navbar-cliente";
import { Navbaradmi } from "./Components/nav-fotter/navbar-admi";
import { Home } from "./Pages/Home";
import { Louding } from "./Components/generales/louding";


/* restarurar contraseña */
import { Restaurarcontraseña } from "./Components/generales/restaurarcontraseña";
import { Generarnuevacontraseña } from "./Components/Log/contraseña/contraseñanuevagenerar";
import { Finalgenerarcontraseña } from "./Components/Log/contraseña/finalverficarcontraseña";


/* registro */
import { Seleccionmemorial } from "./Pages/tipomemorial";
import { RegistroPrincipal } from "./Pages/Registro";
import { Principalformregistro } from "./Pages/Registro-nuevo-usuario";
import { Memorialfull } from "./Components/Log/Registrar/formularios/full";
import { Memorialbasico } from "./Components/Log/Registrar/formularios/basico";
import { Terminoscondiciones } from "./Pages/terminos";
import { Verificarregistrousuario } from "./Components/Log/Registrar/verificarregistro";


/* creacion de memorial */
import { Memorialcrear } from "./Components/Users/Usuariogeneral/creacion memorial/crear memorial";
import { Memorialcreardos } from "./Components/Users/Usuariogeneral/creacion memorial/memorialcreardos";
import { Memorialcreartres } from "./Components/Users/Usuariogeneral/creacion memorial/crearmemorialtres";
import { Demomemorial } from "./Components/Users/Usuariogeneral/demo/demo";
import { Finalcrearmemorial } from "./Components/Users/Usuariogeneral/creacion memorial/finalcreacionmemorial";



/* perfiles */
import { PerfilAdmi } from "./Components/Users/Administrador/perfiladmi";
import { PerfilUsuario } from "./Components/Users/Usuario/Perfilcliente";


/* editar memorial */
import { Editarmemorial } from "./Components/Users/Usuario/funciones-usuario/editarmemorial";
import { Demomemorialdos } from "./Components/Users/Usuariogeneral/demo/demodos";

/* grupofamiliar vista todos */
import GrupoFamiliar from "./Components/pagenPublic/grupoFamiliar";
import { Memorialpagina } from "./Components/pagenPublic/memorial";

/* usuario amdinistrador */
import { Codigos } from "./Components/Users/Administrador/codigos";
import { Generarcodigo } from "./Components/Users/Administrador/generarcodigo";
/* usuario cliente */
import { Usuariocrearfamilia } from "./Components/Users/Usuariogeneral/creacion memorial/creacr-familia";
import { InicioUsuario } from "./Components/Users/Usuario/funciones-usuario/pagina-inicio";
import { MemorialUsuario } from "./Components/Users/Usuario/funciones-usuario/memoriales";
import { Compartirqrusuario } from "./Components/Users/Usuario/funciones-usuario/compartirqrusuario";
import { Terminosusuario } from "./Components/Users/Usuario/funciones-usuario/terminos";
import { Evento } from "./Components/Users/Usuario/funciones-usuario/evento";
import { Crearevento } from "./Components/Users/Usuario/funciones-usuario/crearevento";
import { Perfilcliente } from "./Components/Users/Usuario/funciones-usuario/perfilusuariocliente";
import { Editarevento } from "./Components/Users/Usuario/funciones-usuario/editarevento";


const Layout = () => {
  const basename = process.env.BASENAME || "";
  const [showNavbarFooter, setShowNavbarFooter] = useState(true);
  const [userRole, setUserRole] = useState(null);

  const handleLogin = (role) => {
   
    setUserRole(role);
    sessionStorage.setItem('userRole', role.toString()); // Convertir a cadena y guardar en el almacenamiento local
  };

  const handleLogout = () => {
    setUserRole(null);
    sessionStorage.removeItem('userRole'); // Eliminar el rol del usuario del almacenamiento local
    sessionStorage.removeItem('adminToken'); // Eliminar el token de administrador del almacenamiento de sesión
    sessionStorage.removeItem('ClientToken'); // Eliminar el token del cliente del almacenamiento de sesión
  };

  useEffect(() => {
    const storedUserRole =  sessionStorage.getItem('userRole');
    if (storedUserRole) {
      setUserRole(parseInt(storedUserRole, 10)); // Convertir a número y establecer el estado
    }
  }, []);

  return (
    <BrowserRouter basename={basename}>
      <UsersProvider>
       <AuthProvider>
      
      {userRole == 1 && <Navbaradmi onLogin={handleLogin} onLogout={handleLogout}/>}
      {userRole == 2 && <Navbarcliente onLogin={handleLogin} onLogout={handleLogout} />}
      {showNavbarFooter && !userRole && <Navbar />}
      <Routes>
      
        <Route element={<Home  setShowNavbarFooter={setShowNavbarFooter} onLogin={handleLogin} />} path="/" />
        <Route element={<RegistroPrincipal  setShowNavbarFooter={setShowNavbarFooter} />} path="/Registro" />
        <Route element={<Principalformregistro />} path="/Registroformusuario" />
        <Route element={<Codigos />} path="/codigos" />
        <Route element={<Generarcodigo />} path="/generar-codigo" />
        <Route element={<Usuariocrearfamilia/>} path="/crearfamilia" />
        <Route element={<PerfilAdmi/>} path="/Administrador" />
        <Route element={<PerfilUsuario/>} path="/usuario/:id" />
        <Route element={<Editarmemorial/>} path="/editarmemorial" />
        <Route element={<Louding/>} path="/carga" />
        <Route element={<Demomemorial/>} path="/demomemorial" />
        <Route element={<Demomemorialdos/>} path="/demomemorialvista" />
        <Route element={<Usuariocrearfamilia/>} path="/familia" />
        <Route element={<InicioUsuario/>} path="/usuariosmart" />
        <Route element={<MemorialUsuario/>} path="/usuariomemoriales" />
        <Route element={<Compartirqrusuario/>} path="/compartirusuarioqr" />
        <Route element={<Memorialcrear/>} path="/crearmemorial" />
        <Route element={<Memorialcreardos/>} path="/crearmemorial/galeriavideo" />
        <Route element={<Memorialcreartres/>} path="/crearmemorial/mensajefondo" />
        <Route element={<Finalcrearmemorial/>} path="/qrmemoriales" />
        <Route element={<GrupoFamiliar />} path="/familiar/group/:grupofamiliarId/:funerariaId" />
        <Route element={<Memorialpagina />} path="/familiar/memorial/:id" />
        <Route element={<Terminosusuario/>} path="/terminosycondicionessmart" />
        <Route element={<Evento/>} path="/evento" />
        <Route element={<Crearevento/>} path="/crearevento" />
        <Route element={<Editarevento/>} path="/editarevento" />
        <Route element={<Perfilcliente/>} path="/perfilsmart" />
        <Route element={<Restaurarcontraseña/>} path="/restaurarcontraseña" />
        <Route element={<Generarnuevacontraseña/>} path="/nueva-contraseña" />
        <Route element={<Finalgenerarcontraseña/>} path="/contraseña-nueva-generada" />
        <Route element={<Seleccionmemorial/>} path="/solicitarmemorial" />
        <Route element={<Memorialfull/>} path="/solicitarmemorialfull" />
        <Route element={<Memorialbasico/>} path="/solicitarmemorialbasico" />
        <Route element={<Terminoscondiciones/>} path="/smartmemorial-terminos-y-condiciones" />
        <Route element={<Verificarregistrousuario/>} path="/verificacion-de-registro" />
      </Routes>
      </AuthProvider>
      </UsersProvider>
    </BrowserRouter>
  );
};

export default Layout;
