import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import logofamilia from "../../Image/generales/grupo-familia-2.png";
 import "../../Style/paginavista/familiamemorial.css" 
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import axios from "axios";

function GrupoFamiliar() {
  // Obtiene los parámetros de la URL
  const { grupofamiliarId, funerariaId} = useParams();
  const [data, setData] = useState(null); // Estado local para almacenar los datos
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [logoUrl, setLogoUrl] = useState('');
  const [funerarias, setFunerarias] = useState(null);


  const getGrupoFamiliar = async () => {
    try {
      const response = await axios.get(`${backendURL}/api/get/GrupoFamiliar/public/${grupofamiliarId}`, {
        headers: {
          'Content-Type': 'application/json' // Asegúrate de establecer el tipo de contenido correctamente
        }
      });
     
      setData(response.data); // Almacena los datos en el estado local
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getGrupoFamiliar(); // Invoca la función cuando el componente se monta
  }, []); // La dependencia vacía asegura que solo se ejecute una vez


  useEffect(() => {
    const fetchFuneraria = async () => {
      try {
        if (funerariaId) {
          const response = await axios.get(`${backendURL}/api/funeraria/${funerariaId}`);
          const funerariaData = response.data;
  
            
          if (funerariaData && funerariaData.logo) {
            setFunerarias(funerariaData)
            setLogoUrl(funerariaData.logo);
          }
        } else {
          console.error('Group family or funerary ID is missing');
        }
      } catch (error) {
        console.error('Error fetching funeraria data:', error);
      }
    };

    fetchFuneraria();
  }, [funerariaId]);



  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
};


  return (
    <section className="contienenpaginainiciousuario">
                 {logoUrl && (
         <div className="logofunerariamemorial">
         <img
           src={`${backendURL}/${logoUrl}`}
           alt="Logo de la funeraria"
           className="logofun"

         />
       </div>
        )}

       <Container component="main" maxWidth="xs" >
      <Box
        sx={{
          marginTop: '0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',

        }}
      >
        <div>
          {data && (
            
              <div className="titulogrupofamilia">
                <h3>Grupo Familiar</h3>
                <div className="segundotitulofinal">
                <h1>{data.nombre_familia}</h1>
                <img src={logofamilia} alt="familia smart memorial" className="logosegundotitulofinal" /></div>
              </div>
           
          )}
        </div>
      </Box>

      <div className="rowmemorialesfamiliapagina">
      <div className="titulomemorialestodo">
        
        <h1>Conozca sus  </h1>
        <h1> historias de vida</h1>
        
        <span>haga click  sobre la foto  para visitar el memorial</span>
      </div>
      <div className="contienentodoslosmemorialesfamilia">
      <Box sx={{width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      {data && data.memorial && data.memorial.map((item, index) => (
          
          <Grid container  key={item.id} sx={{ width:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
         <Link 
         style={{width:"100%"}}
      to={{
        pathname: `/familiar/memorial/${item.id}`,
        state: { funeraria: funerarias }
      }}
    >
              <div className="grupofamiliacard">
                <Grid item md={12} sx={{ width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' } } >
              
            <div className="grupofamiliacircle">
              {item && (
                <img
                  src={`${backendURL}/imagenes/memoriales/${item.carpeta_perfil}/${item.imagen_perfil}`}
                  alt="smart memorial"
                  className="grupofamiliaprofileimg"
                  style={{
                    borderColor: (() => {
                      switch (item?.fondo) {
                        case 'naturalezaAustral':
                          return '#E1EDDD';
                        case 'atardecerVerano':
                          return '#F9E7D7';
                        default:
                          return '#E1DBE6';
                      }
                    })()
                  }}
                />
              )} </div>
              </Grid>
              <Grid item md={12} sx={{ width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"-82px"}}>
              <div
                className="grupofamiliacuadrado"
                style={{
                  background: (() => {
                    switch (item?.fondo) {
                      case 'naturalezaAustral':
                        return '#E1EDDD';
                      case 'atardecerVerano':
                        return '#F9E7D7';
                      default:
                        return '#E1DBE6';
                    }
                  })()
                }}
              >
                <div
                  className="grupofamiliacontent"
                  style={{
                    color: (() => {
                      switch (item?.fondo) {
                        case 'naturalezaAustral':
                          return '#27632D';
                        case 'atardecerVerano':
                          return '#A04424';
                        default:
                          return '#D26999';
                      }
                    })()
                  }}
                >
                  <h2 className="name"style={{
                    color: (() => {
                      switch (item?.fondo) {
                        case 'naturalezaAustral':
                          return '#27632D';
                        case 'atardecerVerano':
                          return '#A04424';
                        default:
                          return '#D26999';
                      }
                    })()
                  }}>{item.nombre}</h2>
                  <p className="dates"  style={{
                     color: (() => {
                      switch (item?.fondo) {
                        case 'naturalezaAustral':
                          return '#68A354';
                        case 'atardecerVerano':
                          return '#E28939';
                        default:
                          return '#694B81';
                      }
                    })(),
                  }}>
                    {formatDate(item.fecha_nacimiento)} • {formatDate(item.fecha_defuncion)}
                  </p>
                </div>
              </div>
           
          
               
             </Grid>
             </div>
         </Link>
         </Grid>
        ))}
        </Box>
      </div>
    </div> 
    </Container>
  </section>
  );
}

export default GrupoFamiliar;
