import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel, TablePagination } from '@mui/material';
import { TbFaceIdError } from "react-icons/tb";
import "../../../Style/admi/codigos.css"

export const Codigos = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const adminToken = location.state.user;
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [codigos, setCodigos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [estadoFiltro, setEstadoFiltro] = useState('');
    const [suscripcionFiltro, setSuscripcionFiltro] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        const fetchCodigos = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/getallcodigos`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`
                    }
                });
                setCodigos(response.data);
            } catch (error) {
                console.error("Error al obtener los códigos:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCodigos();
    }, [backendURL, adminToken]);

    const handleEstadoFiltroChange = (event) => {
        setEstadoFiltro(event.target.value);
    };

    const handleSuscripcionFiltroChange = (event) => {
        setSuscripcionFiltro(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredCodigos = codigos.filter((codigo) => {
        return (
            (estadoFiltro === '' || codigo.estado === estadoFiltro) &&
            (suscripcionFiltro === '' || codigo.id_suscripcion.toString() === suscripcionFiltro)
        );
    });

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredCodigos.length - page * rowsPerPage);

    return (
        <Container component="main" maxWidth="md">
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: "column", marginTop: '30px' }}>
                <section className="titulocodigoadmi"><h1>Códigos</h1></section>
                <section className="informacioncodigos"><p>Sección para visualizar los códigos generados para la seguridad de la compra de servicio smart memorial.</p></section>

                <Box sx={{ display: 'flex', width: '100%', marginBottom: '20px' }}>
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id="estado-filtro-label">Estado</InputLabel>
                        <Select
                            labelId="estado-filtro-label"
                            id="estado-filtro"
                            value={estadoFiltro}
                            label="Estado"
                            onChange={handleEstadoFiltroChange}
                        >
                            <MenuItem value=""><em>Todos</em></MenuItem>
                            <MenuItem value="DISPONIBLE">Disponible</MenuItem>
                            <MenuItem value="ACTIVO">Activo</MenuItem>
                            <MenuItem value="INACTIVO">Inactivo</MenuItem>
                            {/* Añade más estados si es necesario */}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 180 }}>
                        <InputLabel id="suscripcion-filtro-label">Suscripción</InputLabel>
                        <Select
                            labelId="suscripcion-filtro-label"
                            id="suscripcion-filtro"
                            value={suscripcionFiltro}
                            label="Suscripción"
                            onChange={handleSuscripcionFiltroChange}
                        >
                            <MenuItem value=""><em>Todos</em></MenuItem>
                            <MenuItem value="1">Smart Memorial Full</MenuItem>
                            <MenuItem value="2">Smart Memorial Basico</MenuItem>
                            {/* Añade más tipos de suscripción si es necesario */}
                        </Select>
                    </FormControl>
                </Box>

                {loading ? (
                    <CircularProgress />
                ) : (
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredCodigos.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Usuario cliente</TableCell>
                                        <TableCell>Suscripción</TableCell>
                                        <TableCell>Código</TableCell>
                                        <TableCell>Estado</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredCodigos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((codigo) => (
                                        <TableRow key={codigo.id}>
                                            <TableCell component="th" scope="row">
                                                {codigo.id_user}
                                            </TableCell>
                                            <TableCell>{codigo.id_suscripcion == 1 ? 'Smart Memorial Full' : 'Smart Memorial Basico'}</TableCell>
                                            <TableCell>{codigo.codigo}</TableCell>
                                            <TableCell>{codigo.estado}</TableCell>
                                        </TableRow>
                                    ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                           <TableCell colSpan={4} align="center">
                                                <div className="contienenerrorcodigo">
                                                    <TbFaceIdError className="iconoerrorcodigo" />
                                                    No existen códigos
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        
                    </Paper>
                )}
            </Box>
        </Container>
    );
};