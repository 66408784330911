import React, { useEffect, useState } from "react";
import axios from 'axios';
import jsPDF from 'jspdf';
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Box, Fab, CircularProgress } from '@mui/material';
import "../../../Style/admi/codigos.css"


export const Generarcodigo = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const adminToken = location.state.user;
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [loading, setLoading] = useState(false);
    const [generatedCode, setGeneratedCode] = useState(null);
    const [selectedService, setSelectedService] = useState('');

    const handleServiceChange = (event) => {
        setSelectedService(event.target.value);
    };
    const handleGenerateCode = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${backendURL}/api/storeCodigo`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`
                }
            });
            setGeneratedCode(response.data.codigo);
            
            
        } catch (error) {
            console.error("Error al generar el código:", error);
            // Manejar el error, por ejemplo, mostrar un mensaje de error
            alert('Error al generar el código');
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadCode = () => {
        // Crear un nuevo documento PDF
        const doc = new jsPDF();
    
        // Agregar contenido al PDF
        doc.text('Código generado:', 10, 10);
        doc.setFontSize(16);
        doc.text(generatedCode, 10, 20);
    
        // Descargar el PDF
        doc.save('codigo_generado.pdf');
    };

    const handleRefreshPage = () => {
        // Recargar la página para generar uno nuevo
        window.location.reload();
    };

    return (
        <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: "column", marginTop: '30px' }}>
            <section className="titulocodigoadmi"><h1>Generación de código de validación</h1></section>
            <section className="informacioncodigos">
                <p>Sección para generar código de validación para el producto Smart Memorial Full.</p>
                <p>Esto se genera para la seguridad del registro del usuario al usar Smart Memorial al comprar directamente de una empresa externa.</p>
            </section>
            <button
                className="botoncodigogenerar"
                onClick={handleGenerateCode}
                disabled={loading}
                
            >
                {loading ? <CircularProgress size={24} /> : "Generar Código"}
            </button>

            {generatedCode && ( // Mostrar el código generado si existe
            <div className="contienecheckcodigo">
            <div class="success-checkmark">
            <div class="check-icon">
              <span class="icon-line line-tip"></span>
              <span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div>
                    <div className="codigogenerado"><p>Código generado:</p> <h1> {generatedCode}</h1></div>
             
             
             
             <section className="contienetodolosbotonescodigo">
              <button className="botoncodigosgenerar" onClick={handleDownloadCode}>Descargar</button>
                <button className="botoncodigosgenerar" onClick={handleRefreshPage}>Actualizar</button>
              </section>
              </div>  )}

              
        </Box>
    </Container>
    )
}