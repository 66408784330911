import React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import '../Style/Registro/tipomemorialpagina.css'

export const Seleccionmemorial = () => {
    return (
        <>
            <Container component="main" maxWidth="xs" >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <section className="titulodesolicitud">
                        <h3>Solicitar</h3>
                        <h1>Memorial</h1>
                    </section>

                    <section className="tectogenerado">
                        <span >Bienvenido(a) a Smart memorial.
                            Le agradecemos por considerar nuestro servicio de 
                            memorial para homenajear y honrar la memoria de 
                             su ser querido y nos complace ofrecerle dos opciones
                              de servicio para que pueda elegir la que mejor se adapte a sus necesidades.
                              </span>
                         
                    </section>

                    <section className="contieneseleciondememorial">
                        <section className="tituloselecion">
                            <h3> Seleccione el servicio que desea</h3>
                        </section>
                        <section>
                            <div className="recuadroinforcionmemorial">
                                <h1>Memorial Full</h1>
                                <span>Placa para lápida + codigo QR + servicio memorial digital x 1 año </span>
                                <div className="preciounasmart">
                                  <p>Precio</p>  <p>$59.990</p> 
                                </div>
                            </div>
                            <div className="solicitaryprecio">
                                <div className="preciosmart">
                                    <h1>$8.990</h1> <span>/</span><span>mantención anual</span>
                                </div>
                                <Link to="/solicitarmemorialfull">
                                <div className="botonsmartsolicitar">
                                    <button> Solicitar</button>
                                </div></Link>
                            </div>
                        </section>
                        <section style={{marginTop:'20px'}}>
                            <div className="recuadroinforcionmemorial">
                                <h1>Memorial Básico</h1>
                                <span>Codigo QR + servicio memorial digital (cuenta Smart Memorial) x 1 año</span>
                           <div className="preciounasmart">
                                  <p>Precio</p>  <p>$15.990</p> 
                                </div>
                                  </div>
                           
                            <div className="solicitaryprecio">
                                <div className="preciosmart">
                                <h1>$8.990</h1> <span>/</span><span>mantención anual</span>
                                </div>
                               <Link to="/solicitarmemorialbasico">
                                <div className="botonsmartsolicitar">
                                    <button> Solicitar</button>
                                </div></Link>
                            </div>
                        </section>
                        

                    </section>

                </Box>
            </Container>


        </>
    )
}