import React, { useState } from "react";
import { useEffect } from 'react'
import "../../../../Style/usuario/memorialesusuario.css"
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Grid } from "@mui/material";
import { FaEllipsis } from "react-icons/fa6";
import { RiFileEditFill } from "react-icons/ri";
import { IoShareSocialSharp } from "react-icons/io5";
import { CgClose } from "react-icons/cg";
import axios from 'axios';
import { FaCirclePlus } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { Modal } from '@mui/material';
import { IoClose } from "react-icons/io5";
import { FiAlertCircle } from "react-icons/fi";
import CircularProgress from '@mui/material/CircularProgress';
import { IoIosArrowBack } from "react-icons/io";



/* imagenes */
import flecha from "../../../../Image/generales/flecha-atras-26.png"
import grupo from "../../../../Image/generales/grupo-familia-2.png"

export const MemorialUsuario = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ClientToken = location.state.user; // Obtener la información del usuario desde las props
  const [grupoFamiliar, setGrupoFamiliar] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [nombreFamilia, setNombreFamilia] = useState('');
  const backendURL = process.env.REACT_APP_BACKEND_URL;
 

  
  useEffect(() => {
    const fetchGrupoUser = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/getGrupoUser/${ClientToken.userId}`, {
          headers: {
            Authorization: `Bearer ${ClientToken.token}`
          }
        });
        setGrupoFamiliar(response.data[0]);
      } catch (error) {
        console.error('Error fetching grupo familiar:', error);
      }
    };

    fetchGrupoUser();
  }, [ClientToken, backendURL]);

  const handleClick = () => {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/compartirusuarioqr', { state });
  };
  const handleClickedit = (memorialId) => {
    const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorialId }; // Pasar el ID del memorial como parte del estado
    navigate('/editarmemorial', { state });
  };
  const handleClickvista = (memorialId) => {
    const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorialId }; 
    navigate(`/familiar/memorial/${memorialId}`, { state });
  };

  const toggleDrawers = () => setOpens(!opens);
  const handleEditClick = () => {
    toggleDrawer();
    setModalOpen(true); // Abre el modal después de hacer clic en editar
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Cierra el modal
  };

  const handleGoBack = () => {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/usuariosmart', { state });
  };
  const handlecrearmemorial = () => {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/crearmemorial', { state });
  };

  const handleNombreChange = (event) => {
    setNombreFamilia(event.target.value);
  };

  const handleGuardarNombre = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${backendURL}/api/updatedFamiliarName/${grupoFamiliar.id}`,
        { nombre_familia: nombreFamilia },
        {
          headers: {
            Authorization: `Bearer ${ClientToken.token}` // Encabezado de autorización
          }
        }
      );

      // Cierra el modal después de editar el nombre de la familia
      handleCloseModal();
      const updatedGrupoFamiliar = { ...grupoFamiliar, nombre_familia: nombreFamilia };
      navigate('/usuariomemoriales', { state: { user: ClientToken, familia: updatedGrupoFamiliar } });
    } catch (error) {
      console.error('Error al actualizar el nombre de la familia:', error);
    }
  };


 

  /* funeraria Logo traer */
  useEffect(() => {
    const fetchFuneraria = async () => {
      try {
        if (grupoFamiliar && grupoFamiliar.id_funeraria) {
          const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
          const funerariaData = response.data;

          if (funerariaData && funerariaData.logo) {
            setLogoUrl(funerariaData.logo);
          } else {
            console.error('Logo not found in funeraria data');
          }
        } 
      } catch (error) {
        console.error('Error fetching funeraria data:', error);
      }
    };

    fetchFuneraria();
  }, [grupoFamiliar]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
};



  /* apertura boton */
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const list = (
    <Box
      sx={{ minWidth: "100%", color: "#694B81" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
      {grupoFamiliar && grupoFamiliar.memorial && grupoFamiliar.memorial.length > 0 && (
    <ListItem button onClick={handleClick}>
      <ListItemIcon style={{minWidth:"25px"}}>
        <IoShareSocialSharp style={{ color: '#694B81', fontSize:"1.1rem" }} /> {/* Cambiar el color del icono */}
      </ListItemIcon>
      <ListItemText primary={<span style={{ color: '#694B81', fontFamily: "Poppins", fontSize: "0.85rem", fontWeight: "500", '@media (max-width: 450px)': {
            padding: '20px 0px',
          } }}>Compartir grupo familiar</span>} /> {/* Cambiar el color del texto */}
    </ListItem>
  )}

        <ListItem button onClick={handleEditClick}>
          <ListItemIcon  style={{minWidth:"25px"}}>
            <RiFileEditFill style={{ color: '#694B81',fontSize:"1.1rem" }} /> {/* Cambiar el color del icono */}
          </ListItemIcon>
          <ListItemText primary={<span style={{ color: '#694B81', fontFamily: "Poppins", fontSize: "0.85rem", fontWeight: "500" }}>Editar nombre del grupo</span>} /> {/* Cambiar el color del texto */}
        </ListItem>
        {grupoFamiliar && grupoFamiliar.memorial && grupoFamiliar.memorial.length < 5 && (
    <ListItem button onClick={handlecrearmemorial}>
      <ListItemIcon  style={{minWidth:"25px"}}>
        <FaCirclePlus style={{ color: '#694B81',fontSize:"1.1rem" }} /> {/* Cambiar el color del icono */}
      </ListItemIcon>
      <ListItemText primary={<span style={{ color: '#694B81', fontFamily: "Poppins", fontSize: "0.85rem", fontWeight: "500" }}>Crear nuevo memorial</span>} /> {/* Cambiar el color del texto */}
    </ListItem>
  )}
      </List>
    </Box>
  );

  

  return (
    <section className="contienenpaginainiciousuario" >
    <>   
      {logoUrl && (
        <div className="logofunerariamemorial">
          <img
            src={`${backendURL}/${logoUrl}`}
            alt="Logo de la funeraria"
            className="logofun"

          />
        </div>
      )}  {grupoFamiliar ? (
  <>
      <Container className="contieneregistro" component="main" maxWidth="xs">
        <Box sx={{
          width:"100%",
          padding: '0px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '-50px',
          '@media (max-width: 700px)': {
            marginTop: '10px'
          }
        }}>

           <button className='boton-anterior' onClick={handleGoBack}>
           <IoIosArrowBack />
          </button>

          <button className='boton-editar' onClick={toggleDrawer(true)} >
            <FaEllipsis />
          </button>

        </Box>

       

        <Box sx={{width:"100%", padding: '0px 0px', display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '250px', '@media (max-width: 420px)': {
            padding: '0px 0px'
          },'@media (max-width: 376px)': {
            padding: '0px 0px'
          }, '@media (max-width: 350px)': {
            padding: '0px 0px'
          }  }}> <Box  sx={{
            paddingLeft:" 10px",
            width:"90%",
            display: 'flex',
            flexDirection:"column",
            alignItems:"flex-start",
            }}>
       
          <h1 className="grupofmailiausuario">Grupo Familiar</h1>
          <div className="nombre-familia-usuario">
           <h1>{grupoFamiliar.nombre_familia}</h1>

            <img src={grupo} alt="samrt memorial" />
          </div>
        </Box >
          <div className="titulomemorialperfiles">
            <h1 translate="no"> Memorial</h1>
          </div>
          <Box sx={{width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
  {grupoFamiliar && grupoFamiliar.memorial && grupoFamiliar.memorial.length > 0 ? (
    grupoFamiliar.memorial.map((memorial) => (
      <div className="contienenmemorialcompleto" key={memorial.id} >
        
          <div className="card" onClick={() => handleClickvista(memorial.id)}>
           
           <Grid container sx={{width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' } }>
            <Grid Item xs={12}  sx={{ width:"100%", height:"auto", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' } }>
            <div className="circle">
              {memorial && (
                <img
                  src={`${backendURL}/imagenes/memoriales/${memorial.carpeta_perfil}/${memorial.imagen_perfil}`}
                  alt="smart memorial"
                  className="profile-img"
                  style={{
                    borderColor: (() => {
                      switch (memorial?.fondo) {
                        case 'naturalezaAustral':
                          return '#E1EDDD';
                        case 'atardecerVerano':
                          return '#F9E7D7';
                        default:
                          return '#E1DBE6';
                      }
                    })()
                  }}
                />
              )}
              </div>
              </Grid>
              <Grid Item xs={12}  sx={{ width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop:"-95px", '@media (max-width: 400px)': {
            marginTop: '-80px'
          }, '@media (max-width: 290px)': {
            marginTop: '-70px'
          }, '@media (max-width: 246px)': {
            marginTop: '-55px'
          } }}>
              <div
                className="cuadrado"
                style={{
                  background: (() => {
                    switch (memorial?.fondo) {
                      case 'naturalezaAustral':
                        return '#E1EDDD';
                      case 'atardecerVerano':
                        return '#F9E7D7';
                      default:
                        return '#E1DBE6';
                    }
                  })()
                }}
              >
                <div
                  className="content"
                  style={{
                    color: (() => {
                      switch (memorial?.fondo) {
                        case 'naturalezaAustral':
                          return '#27632D';
                        case 'atardecerVerano':
                          return '#A04424';
                        default:
                          return '#D26999';
                      }
                    })()
                  }}
                >
                  <h2 className="name" style={{
                    color: (() => {
                      switch (memorial?.fondo) {
                        case 'naturalezaAustral':
                          return '#27632D';
                        case 'atardecerVerano':
                          return '#A04424';
                        default:
                          return '#D26999';
                      }
                    })()
                  }}>{memorial.nombre}</h2>
                  <p className="dates"  style={{
                     color: (() => {
                      switch (memorial?.fondo) {
                        case 'naturalezaAustral':
                          return '#68A354';
                        case 'atardecerVerano':
                          return '#E28939';
                        default:
                          return '#694B81';
                      }
                    })(),
                  }}>
                    {formatDate(memorial.fecha_nacimiento)} • {formatDate(memorial.fecha_defuncion)}
                  </p>
                </div>
              </div>
            </Grid>
            </Grid>
          
          </div>
          

          <section className="editmemorialespecifico">
            <button onClick={() => handleClickedit(memorial.id)}>
              <RiFileEditFill /> Editar memorial
            </button>
          </section>
       
      </div>
    ))
  ) : (
    <section className="alertadenomemorialusuario">
         <FiAlertCircle/>
    <h1>No hay memoriales</h1>
    <button onClick={handlecrearmemorial}>Crear Memorial</button>
    </section>
  )}
</Box>

        </Box>
      </Container>

      <SwipeableDrawer
        anchor={"bottom"}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 10px' }}>
          <Button onClick={toggleDrawer(false)}>
            <CgClose className="botoncierremenutodomemorial" />
          </Button>
        </Box>
        {list}
      </SwipeableDrawer>


      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #585857', borderRadius: "10px", boxShadow: 24, p: 4,  '@media (max-width: 500px)': {
                width: '75%',
                p: 2,
              },
              '@media (max-width: 325px)': {
                width: '85%',
                p: 1,

              } }}>
          <div className="botoncerrar">  <Button onClick={handleCloseModal}><IoClose /></Button></div>
          <div className="cardeditnombrefamilia">
            <ListItemIcon>
              <RiFileEditFill style={{ color: '#694B81' }} className="iconoeditarnombrefamilia" />
            </ListItemIcon>
            <h2 className="labeleditarnombre">Editar nombre familia</h2>
            <div className="inputlabelfamilia">
              <label>Grupo Familiar</label>
              <form >
                <input type="text" name="nombre" value={nombreFamilia} onChange={handleNombreChange} />
                <button className="buttonguardareditarfamilia" onClick={handleGuardarNombre}> Guardar</button>
              </form>

            </div>
          </div> 
        </Box>
      </Modal>
      </>
    ) : (
      <Container className="contieneregistro" component="main" maxWidth="xs">
      <Box sx={{
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '100px',
       
      }}>
        <CircularProgress color="secondary" />
     
</Box>
</Container>
    )}
    </>
    </section>
  );
};