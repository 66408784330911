import React, {useState, useEffect} from "react";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import { FiAlertCircle } from "react-icons/fi";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import "../../Style/login/login.css"
import { useAuth } from "../context/usecontext";
import { useUsers } from "../context/userscontexts";
import { styled } from "@mui/system";
import { CircularProgress } from '@mui/material';





// Tema predeterminado
const defaultTheme = createTheme();


export default function Login({ onLogin }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setAdminAuthToken } = useAuth();
  const { setUserToken} = useUsers();
  const [userData, setUserData] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  
  const handleSubmit = async (event) => { 
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    const password = formData.get('password');
  
    // Validar el correo electrónico
    if (!validateEmail(email)) {
      setEmailError('El correo electrónico no es válido');
      setLoading(false);
      return;
    } else {
      setEmailError('');
    }
  
    try {
      const response = await fetch(`${backendURL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (!response.ok) {
        setPasswordError('El correo o contraseña esta incorrecta');
        setLoading(false); 
        return;
      }
  
      const data = await response.json();
setUserData(data);
const { token, user } = data; // Desestructurar 'data' para obtener el token y el usuario
const userId = user.id;



  
      if  (data.user.id_rol == 1) {
         sessionStorage.setItem('adminToken', data.token);
         setAdminAuthToken(data.token); 
         sessionStorage.setItem('onLogin', 1);
         onLogin(1);
         navigate('/Administrador');
      } else {
        const clientTokenData = { token, userId}; // Agrupar el token y el ID de usuario en un objeto
        sessionStorage.setItem('ClientToken', JSON.stringify(clientTokenData)); // Guardar el objeto como una cadena JSON
        setUserToken(clientTokenData);
        sessionStorage.setItem('onLogin', 2);
        onLogin(2);
        navigate(`/usuario/${userId}`);
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    }
    finally {
      setLoading(false); // Detener el estado de carga después de la solicitud
    }
  };

  useEffect(() => {
    // Comprueba si el usuario ya ha iniciado sesión al cargar el componente
    const token = sessionStorage.getItem("adminToken");
    if (token) {
      navigate("/Administrador");
    }
  else {
    navigate("/")
  }
}, []);

useEffect(() => {
  // Comprueba si el usuario ya ha iniciado sesión al cargar el componente
  const token = sessionStorage.getItem("ClientToken");
  if (token && userData) { // Verificamos si hay un token y datos de usuario disponibles
    navigate(`/usuario/${userData.user.id}`);
  }
}, [userData]);

const validateEmail = (email) => {
  // Expresión regular para validar correo electrónico
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

  return (
    <>
   
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" sx={{
        paddingTop:'40px'
      }}>
        <CssBaseline />
        <Box
          sx={{
            
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            
          }}
        >
          
          <Typography component="h1" variant="h5" sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#D26999' }}>
            Iniciar Sesión 
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width:'100%' }}>
            <Box className="contiene-inputlogin" sx={{ position: 'relative', marginTop:'40px', width:'100%' }}>
              <InputLabel className="label-login" htmlFor="email" sx={{ position: 'absolute', top: -5, color:'#585857',  marginBottom:'0px', fontSize:"0.8rem", left:"25px" }}>
                Correo electrónico
              </InputLabel>
              <StyledTextField 
                margin="normal"
                required
                id="email"
                name="email"
                autoComplete="email"
                className={`input-login ${emailError && 'input-error'}`} 
                autoFocus
                InputProps={{ sx: { borderRadius: '50px', border: `1px solid ${passwordError  ? '#D26999' : '3px solid ##F0EDF3'}`, fontSize:"0.8rem" },
                style: { border: emailError ? '1px solid #D26999' : '3px solid ##F0EDF3',  } }}
                sx={{marginBottom: '10px' }}
              />
               
            </Box>
            
            <Box className="contiene-inputlogin" sx={{ position: 'relative', marginTop:'10px', width:'100%' }}>
              <InputLabel className="labellogin" htmlFor="password" sx={{ position: 'absolute', top: -5, color:'#585857',  marginBottom:'0px', fontSize:"0.8rem", left:"25px"  }}>
                Contraseña
              </InputLabel>
              <StyledTextFields
              className={`input-login ${passwordError && 'input-error'}`} 
                margin="normal"
                required
                name="password"
                id="password"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                InputProps={{ sx: { borderRadius: '50px', border: `1px solid ${passwordError  ? '#D26999' : '3px solid ##F0EDF3'}`, fontSize:"0.8rem" },
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    className="password-toggle-btn"
                  >
                    {showPassword ? <FaRegEyeSlash  className="eye-icon"/> : <FaRegEye  className="eye-icon" />}
                  </IconButton>
                )
              }}
               
              />
             
            </Box>
            <div className="contiene-error-mensaje">  {passwordError && <span className="errormessagelogin"><FiAlertCircle className='icon-error' />{passwordError}</span>} </div>
          <Box sx={{width:"100%", display:'flex', alignItems:'flex-start', position:'relative', }}>
            <div className="aceptar-terminos">
  <FormControlLabel
 
    control={
      <div className="unoterminos">
        <label className="container" htmlFor="checkbox">
          <input type="checkbox" name="checkbox" id="checkbox" />
          <svg viewBox="0 0 64 64" height="1.8em" width="1.8em">
            <path
              d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
              pathLength="575.0541381835938"
              className="path"
            ></path>
          </svg>
        </label>
      </div>
    }
    label="Mantener sesión iniciada"
    sx={{
      width:'100%',
      color: '#585857',
      '& .MuiTypography-root': {
        fontSize: '0.7rem',
        
      }
    }}
  />
</div></Box>
<div className="contienenbotons-login">
            <button
              type="submit"
              
              variant="contained"
              sx={{ mt: 3, mb: 2, background:'#694B81', borderRadius:'40px', padding:'10px 60px', fontWeight:'600', marginBottom:'30px' }}
            >
             {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Iniciar Sesión'}
            </button>
            
            <Grid container>
              <Grid item xs>
                <Link to="/restaurarcontraseña" className="link" >
                <h5 className="Olvidar">He olvidado mi contraseña</h5>
                </Link>
              </Grid>
           
            </Grid> </div>
          </Box>
        </Box>
      
      </Container>
    </ThemeProvider>
    </>
  );
}


const StyledTextField = styled(TextField)({
  width: '100%',
 
  '& .MuiInputBase-root': {
    transition: 'all 0.3s ease',
  },
  '& .MuiInputBase-root.Mui-focused': {
    border: ' 1px solid #585857', // Cambia esto al color que quieras
   
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: ' 1px solid #585857',  // Cambia esto al color que quieras
  },
});

const StyledTextFields = styled(TextField)({
  width: '100%',
 
  '& .MuiInputBase-root': {
    transition: 'all 0.3s ease',
  },
  '& .MuiInputBase-root.Mui-focused': {
    bborder: ' 1px solid #585857', // Cambia esto al color que quieras
    
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: ' 1px solid #585857',  // Cambia esto al color que quieras
  },
});