import React from "react";
import {Grid, Box , CircularProgress, Modal, Typography, Container} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { Await, useLocation, useNavigate } from "react-router-dom";
import "../../../../Style/usuario/evento.css";
import { RxCalendar } from "react-icons/rx";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from "axios";
import { styled } from '@mui/system';
import Select from '@mui/material/Select';
import { FiAlertCircle } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";


export const PerfilUsuario = () => {
const [isLoading, setIsLoading] = useState(false);
const [loading, setLoading] = useState(true);
const backendURL = process.env.REACT_APP_BACKEND_URL;
const location = useLocation();
const navigate = useNavigate();
const [ClientToken, setClientToken] = useState(location.state.user);
const grupoFamiliar = location.state.familia; 
const [newClienToken, setnewClienToken] = useState(null);
const [showModal, setShowModal] = useState(false);
const [errors, setErrors] = useState({});
const [successAlertOpen, setSuccessAlertOpen] = useState(false);
const [errorAlertOpen, setErrorAlertOpen] = useState(false);
const [enviandoAlert, setenviandoAlert] = useState(false);
const [alertMessage, setAlertMessage] = useState('');
const [grupoUser, setGrupoUser] = useState(null);


const handleChange = (e) => {
const { name, value } = e.target;
setGrupoUser({ ...grupoUser, [name]: value });
};

const fetchGrupoUser = async () => {
    try {
        const response = await axios.get(`${backendURL}/api/getUserId/${ClientToken.userId}`, {
            headers: {
                Authorization: `Bearer ${ClientToken.token}`
            }
        });
        setGrupoUser(response.data);
        setLoading(false);
    } catch (error) {
        console.error('Error fetching grupo familiar:', error);
    }
};

    const StyledSelect = styled(Select)(({ theme, error }) => ({
        padding: '1px 13px',
        color:'#694B81',
        position:'relative',
        left:'0.5%',
        fontFamily: "Poppins",
        width:'100%',
        height:'52px',
        fontSize:'0.8rem',
        borderRadius: '50px',
        border: ` ${error ? '1px solid #D26999' : '3px solid #F0EDF3'}`, // Cambia el color del borde si hay un error
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#F0EDF3', // Color del borde por defecto
          },
          '&:hover fieldset': {
            borderColor: '#585857', // Color del borde al pasar el mouse
          },
          '&.Mui-focused fieldset': {
            borderColor: '#585857', // Color del borde en foco
          },
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#585857', // Color del borde en foco
          borderWidth: '2px', // Ancho del borde en foco
        },
        '@media (max-width: 310px)': {
          fontSize:'0.7rem',
          width: '100%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
          maxWidth: '100%',
        },
    }));

    
    const handleUpdatePerfil = async (e) => {
      e.preventDefault(); // Prevenir el comportamiento predeterminado del formulario
  
      try {
          setIsLoading(true);
          setenviandoAlert(true);
          const requestBody = { ...grupoUser };
  
          // Verificar si el campo id_funeraria ha cambiado
          if (requestBody.id_funeraria !== grupoFamiliar.id_funeraria) {
              // Si ha cambiado, asignar el nuevo valor
              requestBody.id_funeraria = grupoFamiliar.id_funeraria;
          }
  
          await axios.post(
              `${backendURL}/api/updateUser/${ClientToken.userId}`,
              requestBody,
              {
                  headers: {
                      Authorization: `Bearer ${ClientToken.token}`
                  }
              }
          );
          setShowModal(true); // Muestra el modal de éxito
          setenviandoAlert(false);
          setSuccessAlertOpen(true);
          setAlertMessage('Se ha editado correctamente su información.');
          await fetchGrupoUser(grupoUser);
  
          // Simulación de tiempo de carga para cerrar la alerta
  
  
      } catch (error) {
          setErrorAlertOpen(true);
          setAlertMessage('Error al editar su información, vuelva a intentar.');
      } finally {
          setIsLoading(false);
          setenviandoAlert(false);
      }
      setTimeout(() => {
          setSuccessAlertOpen(false);
          setErrorAlertOpen(false);
          navigate(`/usuariosmart`, { state: { user: ClientToken, familia: grupoFamiliar} });
      }, 3000);
  };

    const handleCloseModals = () => {
        setenviandoAlert(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        fetchGrupoUser();
    }, [ClientToken, backendURL]);

    if (loading) {
        return <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}><CircularProgress color="secondary" /></Box>;
    }

return (

    <Box sx={{ display:"flex", alignItems:"center", justifyContent:"center"}}>
        <form>
            <Grid container >
                <Grid item xs={12} sx={{  marginBottom: '10px' }}>
                    <div className="inputcrearperfil">
                        <label className="titulocrearperfillabel" htmlFor="">Nombres</label>
                        <input className="crearinputperfil" type="text" name="name" value={grupoUser.name}
                            onChange={handleChange} />
                    </div>
                </Grid>
                <Grid item xs={12} sx={{  marginBottom: '10px' }}>
                    <div className="inputcrearperfil">
                        <label className="titulocrearperfillabel" htmlFor="">Apellidos</label>
                        <input className="crearinputperfil" type="text" name="apellido" value={grupoUser.apellido}
                            onChange={handleChange} />
                    </div>
                </Grid>
                <Grid item xs={12} sx={{  marginBottom: '10px' }}>
                    <div className="inputcrearperfil">
                        <label className="titulocrearperfillabel" htmlFor="editfecha">Fecha de Nacimiento</label>
                        <div className="contienencalendarioperfil">
                            <input type="date" className="crearinputperfil" name='fecha_nacimiento'
                                value={grupoUser.fecha_nacimiento} onChange={handleChange} />
                            <div className="calendarioicono">
                            <RxCalendar className="iconoperfilcalendario" />
                            </div>
                        </div>

                    </div>
                </Grid>
                <Grid item xs={12} sx={{  marginBottom: '10px' }}>
                    <div className="inputcrearperfil">
                        <label className="titulocrearperfillabel">Género</label>
                        < StyledSelect name='genero' sx={{textAlign:"left"}} className={`input-select-registro
                            ${errors.genero && 'input-error' }`} value={grupoUser.genero} onChange={handleChange}
                            displayEmpty inputProps={{ 'aria-label': 'Without label' }}
                            IconComponent={KeyboardArrowDownIcon} error={errors.genero}>
                            <MenuItem value={grupoUser.genero}>
                            <em>{grupoUser.genero}</em>
                            </MenuItem>
                            <MenuItem value={"Masculino"}>Masculino</MenuItem>
                            <MenuItem value={"Femenino"}>Femenino</MenuItem>
                            <MenuItem value={"Me identifico con otro género"}>Me identifico con otro género </MenuItem>
                        </ StyledSelect>
                        {errors.genero && <span className="error-message">
                            <FiAlertCircle className='icon-error' />{errors.genero}</span>}
                    </div>

                </Grid>
                <Grid item xs={12} sx={{  marginBottom: '10px' }}>
                    <div className="inputcrearperfil">
                        <label className="titulocrearperfillabel" htmlFor="">Correo electrónico</label>
                        <input className="crearinputperfil" type="text" name="email" value={grupoUser.email}
                            onChange={handleChange} />
                    </div>
                </Grid>
                <Grid item xs={12} sx={{  marginBottom: '10px' }}>
                    <div className="inputcrearperfil">
                        <label className="titulocrearperfillabel" htmlFor="">Número de celular</label>
                        <input className="crearinputperfil" type="text" name="telefono" value={grupoUser.telefono}
                            onChange={handleChange} />
                    </div>
                </Grid>
            </Grid>

            <section className="botonguardarperfilcreado">
                <button type="submit" disabled={isLoading} onClick={handleUpdatePerfil}>
                    {isLoading ?
                    <CircularProgress size={24} /> : "Guardar Cambios"}
                </button>
            </section>
        </form>
        
        <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
    </Box>

);
}


