import React, { useState, useEffect } from "react";
import { AppBar, Box, Container, IconButton, Toolbar, Tooltip, Drawer, List, ListItem, ListItemButton, ListItemText, Divider, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import "../../Style/navbar/navbar.css"
import LogoutIcon from '@mui/icons-material/Logout';

import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";




/* imagenes */
import logo from "../../Image/navbar/movil-smart-memorial-header.png"
import { Grid3x3Sharp } from "@mui/icons-material";


const AnchorTemporaryDrawer = ({ open, onClose}) => {
  

  return (
    <StyledDrawer
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{ width:400, padding: '50px 0px', background: "#431E62", color: '#fff', '@media (max-width: 400px)': {
          width: '100%',
        }, }}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
        id="menunavbaradmi"
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop:'-20px' }}>  
          <IconButton onClick={onClose} color="inherit">
            <CloseIcon className="iconoclosenav" />
          </IconButton>
        </Box>
        <List sx={{marginTop:"50px"}}>
        <ListItem disablePadding sx={{marginBottom:"10px"}}>
        
          </ListItem>
          <ListItem disablePadding sx={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'-15%', marginBottom:'5%'}} >
            <img className='logodesplieguenav' src={logo} alt='smar memorial'  />
          </ListItem>
          <Link to ='/'>
          <ListItem disablePadding >
            <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center', fontSize:'0.9rem', color:'#fff'}}>
            Acceder a mi cuenta
            </StyledListItemButton>
          </ListItem>
          </Link>

          <Link to='/solicitarmemorial'>
          <ListItem disablePadding  >
            <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center',  fontSize:'0.9rem', color:'#fff'}}>
            Solicitar memorial
            </StyledListItemButton>
          </ListItem>
           </Link>
          <Link to="/smartmemorial-terminos-y-condiciones">
          <ListItem disablePadding >
            <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center',  fontSize:'0.9rem', color:'#fff'}}>
            Términos y condiciones
            </StyledListItemButton>
          </ListItem>
         </Link>
        </List>
        <Divider sx={{ background: "#fff", margin:"10px 10px" }} />
        <List>
          <ListItem disablePadding>
            <StyledListItemButtonfinal >
              <div className="informacionnav">
                <span>Si necesita más información,</span>
                <span>escríbanos un correo a:</span>
                <a href="mailto:smartmemorialok@gmail.com">
                <button>smartmemorialok@gmail.com</button>
                </a>
              </div>
            
            </StyledListItemButtonfinal>
            </ListItem>
            <ListItem disablePadding>
            <StyledListItemButtonfinal >
              <div className="redessociales">
              <div className="tituloredesnav">
                <h3>Síguenos en:</h3>
              </div>
              <Grid container sx={{width:"60%",display:"flex",justifyContent:"center", alignItems:"center"}} >
                <Grid item xs={4} sx={{width:"50%"}} >
                  <div className="rednav"> <FaFacebook /> </div>
                </Grid>
                <Grid item xs={4} sx={{width:"50%"}}> <div className="redimstanav"> <FaSquareInstagram /> </div></Grid>
                <Grid item xs={4} sx={{width:"50%"}}> <div className="rednav"> <FaTiktok /></div></Grid>
              </Grid >
              </div>
            </StyledListItemButtonfinal>
          </ListItem>
        </List>

        
      </Box>
    </StyledDrawer>
  );
};


export const Navbar =() => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const toggleDrawer = (open) => () => {
      setDrawerOpen(open);
    };
  
  

  return (
    <StyledAppBar position="static" className='nav'>
     
        <Toolbar disableGutters>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' }, padding:'0px 30px' }}>
          <Link to='/'>  <img  className='logo-nav' src={logo} alt='smar memorial'/>
        </Link>
          </Box>
          
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
           
         
          </Box>

          <Box sx={{ flexGrow: 0, padding:'0px 30px' }}>
            <Tooltip title="Open settings">
            <IconButton
                size="large"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
              <MenuIcon />
            </IconButton>
             
            </Tooltip>
            <AnchorTemporaryDrawer open={drawerOpen} onClose={toggleDrawer(false)}/>
          </Box>
        </Toolbar>
      
    </StyledAppBar>
  );
}


const StyledAppBar = styled(AppBar)({
  background:"#431E62"
});


const StyledDrawer = styled(Drawer)({
  '& .MuiPaper-root': { // Selecciona el Paper dentro del Drawer
    background: '#431E62', // Cambia el color de fondo
  },
});

const StyledListItemButton = styled(ListItemButton)({
  fontSize:"1.1rem",
  marginBottom:"10px"
});

const StyledListItemButtonfinal = styled(ListItemButton)({
  display: "flex",
   alignItems:"center",
   justifyContent:"center",
  marginBottom:"10px"
});

const StyledGrid  = styled(Grid )({
  display: "flex",
   alignItems:"center",
   justifyContent:"center",
   flexDirection:"row,"
});
