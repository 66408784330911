import React from "react";
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../Style/usuario/crear-familiar.css";
import "../../../../Style/usuario/inicio-usuario.css";
/* elmentos */
import axios from 'axios';
import Box from "@mui/material/Box";
import { RxCalendar } from "react-icons/rx";
import { ImSearch } from "react-icons/im";
import Modal from '@mui/material/Modal';
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { PiScrollFill } from "react-icons/pi";
import { FiAlertCircle } from "react-icons/fi";
import dayjs from 'dayjs';
import { Container } from "@mui/material";
import { CircularProgress } from '@mui/material';
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Estilos por defecto




/* imagenes */

import logomemorialdos from "../../../../Image/generales/grupo-familia-2.png";

import crear from "../../../../Image/generales/crear-memorial.png";




export const Memorialcrear = ( ) =>{
  const location = useLocation();
  const navigate = useNavigate();
  const ClientToken = location.state.user; // Obtener la información del usuario desde las props
  const grupoFamiliar = location.state.familia;
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("");
  const [nombreFallecido, setNombreFallecido] = useState('');
  const [fechaNacimiento, setFechaNacimiento] = useState('');
  const [fechaFallecimiento, setFechaFallecimiento] = useState('');
  const [cementerio, setCementerio] = useState('');
  const [imagenPerfil, setImagenPerfil] = useState(null);
  const [imagenPreview, setImagenPreview] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [historia, setHistoria] = useState('');
  const fechaFormateada = dayjs(fechaNacimiento).format('DD/MM/YYYY');
  const fechaNacimientoFormateada = fechaNacimiento ? dayjs(fechaNacimiento).format('YYYY/MM/DD') : '';
  const fechaFallecimientoFormateada = fechaFallecimiento ? dayjs(fechaFallecimiento).format('YYYY/MM/DD') : '';
  const [guardadoParcial, setGuardadoParcial] = useState(false);
  const [memorialId, setMemorialId] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [hasMemorials, setHasMemorials] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [enviandoAlert, setenviandoAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [newgruopfamily, setNewgruopfamily] = useState(null)

 

  useEffect(() => {
    if (grupoFamiliar && grupoFamiliar.memorial) {
      setHasMemorials(grupoFamiliar.memorial.length > 0);
    }
  }, [grupoFamiliar]);

  const handleClick = () => {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/usuariosmart', { state });
  }; 
  const handlePart1Submit = async (e) => {
    e.preventDefault();
    const fechaNacimientoFormateada = fechaNacimiento ? dayjs(fechaNacimiento).format('YYYY/MM/DD') : '';
    const fechaFallecimientoFormateada = fechaFallecimiento ? dayjs(fechaFallecimiento).format('YYYY/MM/DD') : '';
    
    // Validar que todos los campos requeridos estén completos
    if (nombreFallecido && fechaNacimientoFormateada && fechaFallecimientoFormateada) {
        // Si todos los campos están completos, avanzar a la siguiente página
        setCurrentPage(currentPage + 1);
        setGuardadoParcial(true);
    } else {
        // Si falta algún campo, mostrar un mensaje de error
        setError("Todos los campos son requeridos");
    }
};

const handlePart2Submit = async (e) => {
  e.preventDefault();
  setIsSubmitting(true);
  const formData = new FormData();
  formData.append('nombre', nombreFallecido);
  formData.append('cementerio', cementerio);
  formData.append('fecha_nacimiento', fechaNacimientoFormateada);
  formData.append('fecha_defuncion', fechaFallecimientoFormateada);
  formData.append('imagen_perfil',imagenPerfil);
  formData.append('historia', historia);

  try {
    setenviandoAlert(true);
    const response = await axios.post(`${backendURL}/api/memorialStore/${grupoFamiliar.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${ClientToken.token}` // Asegúrate de tener el token de autenticación disponible
      }
    });
    const memorialId = response.data.id;

    // Actualizar el estado del componente con el ID del memorial creado
    setMemorialId(memorialId);
        setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('El memorial se a creado con exito.')
    
    const updatedGrupoFamiliar = {
      ...grupoFamiliar,
      memorial: [...grupoFamiliar.memorial, response.data]
    };

    setNewgruopfamily(updatedGrupoFamiliar)

      // Aquí puedes manejar la respuesta como desees
  } catch (error) {
    const errorMessage = error.response && error.response.data && error.response.data.message 
    ? error.response.data.message 
    : 'Error al crear el memorial.';

  setErrorAlertOpen(true);
  setAlertMessage(errorMessage);
 
  }
  finally {
    setIsSubmitting(false); // Ocultar el CircularProgress al finalizar el envío del formulario
    setenviandoAlert(false);
  }
  // Simulación de tiempo de carga
 setTimeout(() => {
  handleCloseModal();
}, 3000);

// Simulación de tiempo de carga
setTimeout(() => {

  setErrorAlertOpen(false);
}, 2000);
}; 

useEffect(() => {
  if (!errorAlertOpen && alertMessage) {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/usuariosmart', { state });
  }
}, [errorAlertOpen]);

const handleAccept = () => {
  setSuccessAlertOpen(false);
  const state = { user: ClientToken, familia: newgruopfamily, memorial: memorialId };
  navigate('/crearmemorial/galeriavideo', { state });
};

const handleCloseModal = () => {
  setenviandoAlert(false);
};


/* historia */
const handleChange = (value) => {
  setHistoria(value);
};

/* imagen perfil */

const handleImagenSeleccionada = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagenPreview(reader.result); // Actualiza la vista previa de la imagen
    };
    reader.readAsDataURL(file);
    setImagenPerfil(file); // También actualiza la imagen seleccionada
  }
};

/* google maps api */
 /*  const [selectedPlace, setSelectedPlace] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });
   */
/* 
  
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    console.log('Input value:', inputValue);
    if (inputValue) {
      searchPlaces(inputValue);
    } else {
      setPredictions([]);
      setSelectedPlace(null);
    }
  };

  const handlePlaceChanged = () => {
    const input = document.getElementById('cementerio');
    const autocomplete = new window.google.maps.places.Autocomplete(input);
    autocomplete.setTypes([]); // Sin restricciones de tipo
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      setSelectedPlace(place);
      console.log('Selected place:', place); // Verifica el lugar seleccionado
      setInputValue(place.formatted_address);
      console.log('Formatted address:', place.formatted_address); // Verifica la dirección formateada
      setPredictions([]); // Limpiar las predicciones después de seleccionar un lugar
    });
  };

  const searchPlaces = (input) => {
    const autocompleteService = new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
      console.log('Predictions:', predictions); // Verifica las predicciones recibidas
      console.log('Status:', status); // Verifica el estado de la solicitud
      if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
        setPredictions(predictions);
      } else {
        setPredictions([]);
      }
    });
  }; */

/* paginas */

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };


  /* funeraria Logo traer */
  useEffect(() => {
    const fetchFuneraria = async () => {
      try {
        if (grupoFamiliar && grupoFamiliar.id_funeraria) {
          const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
          const funerariaData = response.data;
          
          
          if (funerariaData && funerariaData.logo) {
            setLogoUrl(funerariaData.logo);
          }
        } else {
          console.error('Group family or funerary ID is missing');
        }
      } catch (error) {
        console.error('Error fetching funeraria data:', error);
      }
    };

    fetchFuneraria();
  }, [grupoFamiliar]);


   

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


    return(
      

        <section className="contienenpaginainiciousuario" >
    {logoUrl && (
         <div className="logofunerariamemorial">
         <img
           src={`${backendURL}/${logoUrl}`}
           alt="Logo de la funeraria"
           className="logofun"

         />
       </div>
        )}
          <section className={`pagina-${currentPage}`}>
       

            {currentPage === 1 && (
                
                 <Container component="main" maxWidth="xs">
                   <Box
          sx={{
            width:"100%",
            display: "flex",
            flexDirection: "column",
            background: "#ffff",
            justifyContent:'center',
            alignItems:'center'
           
          }}
        > 
       
          <div className="pasosmemorial">
          <div className="titulopasos"><h1>PASO 1:</h1>
          <span>Cree un Memorial para su ser querido</span>
          </div></div>
                 <section className="portada-familia-crear">
                  
                    
                   <section className="titulo-crear-familia">
                     <h1 className="titulofamiliafinal">Grupo Familiar</h1>
                     <div className="apellido-crear">
                     <h1>Familia {grupoFamiliar.nombre_familia}</h1>
                     
                       <img
                         src={logomemorialdos}
                         className="imagen-seccion2"
                         alt="smart memorial"
                       />
                     </div>
                   </section>
                 </section>
                 <section className="contienetodocrearmemorial">
                   
                   <div className="titulopartedos">
                     <img src={crear} alt="smart memorial" />
                     <h1 className="titulo-memoria">Crear Memorial</h1>
                   </div>
                   
                    <section  className="memorialaviso"><span>Recuerde que son 5 memoriales como máximo</span> </section>

                   <form className="formcrearmemorial" onSubmit={handlePart1Submit}>
                   <div className="inputregistorform">
                       <label>Nombre del fallecido</label>
          <div className="contieneinputmemorial">
          <input value={nombreFallecido} className={`inputregistrofallecido ${error && 'input-error'}`}   name="nombres"
                         id="name" onChange={(e) => setNombreFallecido(e.target.value)} />
               </div>           {error && <div className="errorinput"><FiAlertCircle />{error}</div>}
          </div>
          <div className="inputregistorform">
                       <label htmlFor="editfecha">Fecha de Nacimiento</label>
                       <div className="inputcreacioncontainer">
        <input 
            type="date"  
            placeholder=""
            className={`crearinputperfil ${error && 'input-error'}`}
            name='fechaNacimiento'  
            value={fechaNacimiento} 
            onChange={(e) => setFechaNacimiento(e.target.value)} 
        />
        <div className="calendariocreacionicono">
        <RxCalendar className="iconocrearmemorialcalendario" />
        </div>
    </div>
          {error && <div className="errorinput"><FiAlertCircle />{error}</div>}                
                     </div>
                     <div className="inputregistorform">
                       <label htmlFor="editfecha">Fecha de Fallecimiento</label>
                       <div className="inputcreacioncontainer">
        <input 
            type="date"  
            className={`crearinputperfil ${error && 'input-error'}`}
            name='fechaNacimiento'  
            onChange={(e) => setFechaFallecimiento(e.target.value)} 
             value={fechaFallecimiento}
        />
        <div className="calendariocreacionicono">
        <RxCalendar className="iconocrearmemorialcalendario" />
        </div>
    </div>
        {error && <div className="errorinput"><FiAlertCircle />{error}</div>}                  
                     </div>
                     

                     <div className="inputregistorform">
                     <label>Cementerio (opcional)</label>
                     <div className="input-cementerio">
     
       
          <input
            type='text'
            className="inputregistrofallecido"
            name="cementerio"
            id="cementerio"
            placeholder="Buscar cementerios"
            value={cementerio} onChange={(e) => setCementerio(e.target.value)}
          />
          <span className="buscador">
            <ImSearch />
          </span>
          
                 
                </div>  
               
                </div>   
                <div className="botones">
                     <div>
      {hasMemorials ? (
        <div className="botonomitir">
          <button onClick={handleClick}>Cancelar </button>
        </div>
      ) : (
        <div className="botonomitir">
          <button onClick={handleClick}>Omitir</button>
        </div>
      )}
    </div>
                     
                     <div className="contiene-boton-siguiente">
                      
                         <div className="botonregistro">
                           <button type="submit">Siguiente</button>
                         </div>
                    
                     </div>
                     </div>

          
        </form></section>
                 
                 </Box>
                 </Container>
               
                 )}
                 
                  {currentPage === 2 && (
                  
                      <Container className="contieneregistro"component="main" maxWidth="xs">
                             <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      background: "#ffff",
                      justifyContent:'center',
                      alignItems:'center'
                     
                    }}
                  >
                    
                    <div className="pasosmemorial">
                    <div className="titulopasos"><h1>PASO 2:</h1>
                    <span>Elija una foto de perfil y escriba su historia</span>
                    </div></div>
              <section className="portada-familia-crear">
               
                <div className="titulo-fallecido">
                  <h1>Memorial</h1>
                </div>
                <div className="nombre-fallecido">
                  <h1>
                    <strong>{nombreFallecido}</strong>
                  </h1>

                  <span>{formatDate(fechaNacimientoFormateada)} • {formatDate(fechaFallecimientoFormateada)}</span>
                </div>
                <form onSubmit={handlePart2Submit}>
       
          <section className="contiene-input-imagen-perfil">
      <label htmlFor="file-input" className="input-subir">
        <input id="file-input" name="file" type="file" onChange={handleImagenSeleccionada} />
        <div className="contiene-icono">
          <FaArrowUpFromBracket className="imgicono" />
          <span>Subir Foto</span>
        </div>
        {imagenPreview && <img src={imagenPreview} alt="Vista previa de la imagen" />}
      </label>
    </section>
    <section className="historia-crear">
                  <div className="titulohitoriacrear">
                    <PiScrollFill />
                    <h1>Su historia</h1>
                  </div>
                  <ReactQuill
                  
        className="input-historia"
        placeholder="Escriba hechos importantes en la vida de su ser querido..."
        value={historia}
        onChange={handleChange}
        modules={{
          toolbar: [
            [],
            [],
            ['link'],
           
          ],
        }}
        formats={[
          'header', 'font', 'size',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'indent',
          'link', 'image', 'video'
        ]}
      />
                </section>  

                <div className="botones">
                <div className="boton-regreso-crear">
                       {currentPage !== 1 && (
                         
                         <div className="botonomitir">
                           <button onClick={handlePrevPage}>Anterior</button>
                         </div>
                       )}
                     </div>
                     <div className="botonregistro">     
          <button type="submit">{isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Siguiente'}</button>
        </div>  
                    </div>
        </form>
              </section> 
              </Box>
              </Container>
                     
             
            )}
           
                 </section>

                 
      <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
         
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              Recuerda que  si retrocedes nuevamente a paso 1 y paso 2  se realizara nueva creación de memorial. 
            </p>
            <div className="contienebotonaceptar">  <button className="botonaceptar" onClick={handleAccept}>Aceptar</button></div>
          </section>
        </Box>
      </Modal>
      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
             
           </section>
        
      
    )
}